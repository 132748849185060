import React from 'react';
import PropTypes from 'prop-types';
import GeneralCell from './GeneralCell';
import StatusIndicator from './empanelment/appointments/shared/StatusIndicator';

function UdsStatusCell({ testID, uds: { status } }) {
  return (
    <GeneralCell
      testID={testID}
      data={
        <div className="flex items-center gap-3">
          <StatusIndicator status={status} />
          <div className="flex flex-col">
            <span>{status ? 'Submitted' : 'Not Submitted'}</span>
          </div>
        </div>
      }
    />
  );
}

UdsStatusCell.propTypes = {
  testID: PropTypes.string,
  uds: PropTypes.shape({
    status: PropTypes.bool,
  }).isRequired,
};

UdsStatusCell.defaultProps = {
  testID: 'udsStatusCell',
};

export default UdsStatusCell;
