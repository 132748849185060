import React from 'react';
import PropTypes from 'prop-types';
import MemberGroupDetails from './MemberGroupDetails';
import { mdyyyyFromDate } from '../helpers/DateFormatter';

function MemberSummary({ member, type, showExtraDetails }) {
  const isAppointment = type === 'Appointment';
  const noGroupText = `Member does not have ${isAppointment ? 'an' : 'a'} ${type.toLowerCase()}.`;
  const dateOfBirth = member.dateOfBirth ? mdyyyyFromDate(member.dateOfBirth) : '';

  const renderGroupDetails = () => {
    if (member.group) {
      return (
        <MemberGroupDetails
          title={`Current ${type}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...member.group}
        />
      );
    }

    return <p className="mb-0 italic text-center text-gray-700">{noGroupText}</p>;
  };

  const renderAppointmentDetails = () => (
    <div className="grid grid-cols-4">
      <div>
        <p className="text-base font-semibold mb-2">Date of Birth</p>
        <p>{dateOfBirth}</p>
      </div>
      <div>
        <p className="text-base font-semibold mb-2">Phone Number</p>
        <p>{member.phoneNumber}</p>
      </div>
      <div>
        <p className="text-base font-semibold mb-2">Default Facility</p>
        <p>{member.defaultLocation?.name ?? '-'}</p>
      </div>
      <div>
        {member.group ? (
          <MemberGroupDetails
            title="Current Home Group"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...member.group}
            isSecondaryVariant={isAppointment}
          />
        ) : (
          <div>
            <p className="text-base font-semibold mb-2">Current Home Group</p>
            <p>Member does not have a home group.</p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="px-6 py-4">
        <p className="font-semibold mb-2">Member</p>
        {member.name}
      </div>
      <div className="border-t border-gray-300 px-6 py-4">
        {showExtraDetails ? renderAppointmentDetails() : renderGroupDetails()}
      </div>
    </>
  );
}

MemberSummary.propTypes = {
  member: {
    name: PropTypes.string,
    dateOfBirth: PropTypes.string,
    phoneNumber: PropTypes.string,
    defaultLocation: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    group: {
      date: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      counselor: PropTypes.string.isRequired,
    },
  }.isRequired,
  type: PropTypes.string.isRequired,
  showExtraDetails: PropTypes.bool,
};

MemberSummary.defaultProps = {
  showExtraDetails: false,
};

export default MemberSummary;
