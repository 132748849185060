import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CgZoomIn as ZoomIcon } from 'react-icons/cg';
import GeneralCell from './GeneralCell';
import PhotosCarouselModal from './PhotosCarouselModal';
import { convertToSupportedTimeZone } from '../helpers/TimezoneHelper';
import { DEFAULT_DATETIME_FORMAT } from '../helpers/DateFormatter';

function PhotosPreviewCell({ testID, photos, label, datetime }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [convertedDate, timezone] = convertToSupportedTimeZone(datetime);

  if (!photos) {
    return <GeneralCell testID={testID} data={null} />;
  }

  return (
    <GeneralCell
      testID={testID}
      data={
        <>
          <PhotosCarouselModal
            header={label}
            photos={photos}
            isOpen={isPreviewOpen}
            onClose={() => setIsPreviewOpen(false)}
          />
          <div className="flex flex-col">
            <button
              type="button"
              className="flex items-center gap-0.5"
              onClick={() => setIsPreviewOpen(true)}
              data-testid="photo-button"
            >
              <ZoomIcon className="w-5 h-5 text-green-800" />
              <span className="text-green-800 underline">View</span>
            </button>
            {datetime && (
              <span className="text-sm text-gray-700">{`${convertedDate.format(DEFAULT_DATETIME_FORMAT)} ${timezone.abbrev}`}</span>
            )}
          </div>
        </>
      }
    />
  );
}

PhotosPreviewCell.propTypes = {
  testID: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
  datetime: PropTypes.string.isRequired,
};

PhotosPreviewCell.defaultProps = {
  testID: 'photos-status-cell',
  label: 'Photo Preview',
};

export default PhotosPreviewCell;
