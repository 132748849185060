/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import CircularProgress from '@mui/material/CircularProgress';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import GROUP_TABLE_HEADER_DATA from './GroupTableHeaderData';
import GROUP_TABS_KEYS from './GroupTabKeys';
import { formatLastModifiedTime, formatShortDayTime } from '../../DateFormatHelper';

import Button from '../../shared/Button';
import GroupTabData from './GroupTabData';
import Table from '../../Table';
import Tabs from '../../Tabs';
import setRequestParams from './set-request-params';
import useFilterData from './fetch-group-filter-data';
import UpsertHomeGroupModal from '../../UpsertHomeGroupModal';
import UpsertMakeupGroupModal from '../../UpsertMakeupGroupModal';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from '../../shared/AppliedFilters';
import ManagementGroupDeleteModal from '../../ManagementGroupsDeleteModal';
import AddMemberModal from '../../AddMemberModal';
import AddMemberConfirmationModal from '../../AddMemberConfirmationModal';
import Toasts from '../../shared/Toasts';
import useToasts from '../../shared/hooks/use-toasts';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { CLINICAL_MODEL_LABELS } from '../../../helpers/constants';
import useFilters from '../../shared/hooks/use-filters';
import useHttp from '../../shared/hooks/use-http';
import { areStringsEqual } from '../../../helpers/utils';
import { DEFAULT_FILTERS, FILTER_TYPES } from './constants';

const { HOME_GROUP, MAKEUP_GROUP } = GROUP_TABS_KEYS;

const isModalityVirtualOnly = (modality) => modality.label.toLowerCase().trim() === 'virtual only';

function Main({
  lastModifiedTime,
  groupSchedulingEnabled,
  authenticityToken,
  secondaryGroupTypeFeatureFlag,
  groupsManagementPermission,
}) {
  const testID = 'managementMainEmp';
  const AVAILABILITY_DATA = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
    { value: 'all', label: 'All' },
  ];
  const FIFTY_MILES = 50;
  const defaultAvailabilityFilter = [AVAILABILITY_DATA[0]];

  const [homeGroupData, setHomeGroupData] = useState({});
  const [makeupGroupData, setMakeupGroupData] = useState({});
  const [activeTab, setTab] = useState(GroupTabData[0]);
  const [showFilters, setShowFilters] = useState(false);
  const { filters, setFilter, filtersArray } = useFilters(DEFAULT_FILTERS);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { toasts, addToast, removeToast } = useToasts();
  const { toasts: distanceToasts, addToast: addDistanceToast, removeToast: removeDistanceToast } = useToasts();
  const { isLoading: isDeleteGroupLoading, sendRequest: sendDeleteGroupRequest } = useHttp();
  const { isLoading: isGroupsLoading, sendRequest: sendGetGroupsRequest } = useHttp();
  const { isLoading: isAddMemberLoading, sendRequest: sendAddMemberRequest } = useHttp();
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState();
  const [isAddMemberConfirmationModalOpen, setIsAddMemberConfirmationModalOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState();

  const isHomeGroup = activeTab.key === HOME_GROUP;
  const isMakeupGroup = activeTab.key === MAKEUP_GROUP;
  const FilterBtnIcon = showFilters ? HiChevronUp : HiChevronDown;
  const currentDataModel = isHomeGroup ? homeGroupData : makeupGroupData;
  const selectedZipcode = get(filters[FILTER_TYPES.zipcode], '[0].value', undefined);

  const {
    hoursData,
    daysData,
    empanelmentClinicalModelData,
    facilitiesData,
    modalityData,
    officeManagerData,
    staffData,
    statesData,
    clinicalModelData,
    empanelmentSecondaryGroupTypeData,
    isLoading: isFilterDataLoading,
  } = useFilterData();
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [rowDataToUpdate, setRowDataToUpdate] = useState(null);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const setCurrentData = (updatedData) => {
    if (isHomeGroup) {
      setHomeGroupData(updatedData);
    } else {
      setMakeupGroupData(updatedData);
    }
  };

  const fetchData = async (pageNumber = 1, overrideData = false) => {
    setCurrentPage(pageNumber);
    const updatedParams = setRequestParams(pageNumber, filters, isHomeGroup, isMakeupGroup);

    try {
      const response = await sendGetGroupsRequest('/staff/empanelment_groups', {
        params: updatedParams,
      });

      if (!overrideData) {
        const existingJsonData = get(isHomeGroup ? homeGroupData : makeupGroupData, 'json_data', []);
        response.json_data = existingJsonData.concat(response.json_data);
      }

      const hasDistanceRecord = response.json_data.some((row) => row.empanelment_group.distance !== undefined);
      if (hasDistanceRecord) {
        response.json_data.sort((a, b) => a.empanelment_group.distance - b.empanelment_group.distance);

        const hasRecordWithDistanceBelow50 = get(response, 'json_data', []).some(
          (row) => row.empanelment_group.distance < FIFTY_MILES
        );
        if (!hasRecordWithDistanceBelow50 && selectedZipcode && !distanceToasts.length) {
          addDistanceToast({
            header: `There are no offices within 50 miles of ${selectedZipcode}`,
            type: 'warning',
          });
        }
      }

      setCurrentData(response);
    } catch (error) {
      window.Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchData(1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters[FILTER_TYPES.availability],
    filters[FILTER_TYPES.counselor],
    filters[FILTER_TYPES.days],
    filters[FILTER_TYPES.facility],
    filters[FILTER_TYPES.clinicalModel],
    filters[FILTER_TYPES.modality],
    filters[FILTER_TYPES.officeManager],
    filters[FILTER_TYPES.provider],
    filters[FILTER_TYPES.state],
    filters[FILTER_TYPES.time],
    filters[FILTER_TYPES.zipcode],
  ]);

  const tabChange = (tab) => {
    if (tab.key === HOME_GROUP) {
      setFilter(FILTER_TYPES.availability, defaultAvailabilityFilter);
    } else {
      setFilter(FILTER_TYPES.zipcode, []);
      setFilter(FILTER_TYPES.modality, []);
      setFilter(FILTER_TYPES.availability, []);
    }

    setTab(tab);
  };

  const onClickEditRow = (row) => {
    const [startTime, timezone] = convertToSupportedTimeZone(row.empanelment_group.date_time);

    const validateDate = (date) => (date ? convertToSupportedTimeZone(date)?.[0]?.startOf('day') : null);

    const clinicalModel = row.default_clinical_model;
    const isWeeklyProviderGroup = areStringsEqual(clinicalModel?.label, CLINICAL_MODEL_LABELS.WEEKLY_PROVIDER_GROUP);

    const preparedData = {
      facility: row.default_facility,
      counselor: row.default_counselor,
      provider: row.default_provider,
      officeManager: row.default_office_manager,
      dayOfWeek: startTime.format('dddd'),
      startTime,
      timezone,
      modality: row.default_modality,
      groupType: row.default_group_type,
      secondaryGroupTypes: row.default_secondary_group_types,
      clinicalModel,
      counselorGroupOccurrence: isWeeklyProviderGroup ? undefined : validateDate(row.next_counselor_group),
      providerGroupOccurrence: validateDate(row.next_provider_group),
      groupOccurrence: validateDate(row.next_group_occurence),
      id: row.id,
    };

    setShowUpsertModal(true);
    setRowDataToUpdate(preparedData);
  };

  if (!groupSchedulingEnabled) {
    return (
      <div data-testid={testID}>
        <p className="text-center">The Groups Management feature is currently disabled.</p>
      </div>
    );
  }

  const displayLastUpdateTime = () => {
    if (lastModifiedTime) {
      return (
        <p data-testid={`last-updated-time-${testID}`} className="text-xs text-gray-700 mt-2">
          Last updated <time>{formatLastModifiedTime(lastModifiedTime)}</time>
        </p>
      );
    }
    return null;
  };

  const updateRow = (updatedRow) => {
    const updatedData = isHomeGroup ? homeGroupData : makeupGroupData;
    const updatedRows = updatedData.json_data.map((row) => (row.id !== updatedRow.id ? row : updatedRow));
    setCurrentData({ ...updatedData, json_data: updatedRows });
  };

  const onUpsertClose = () => {
    setShowUpsertModal(false);
    setRowDataToUpdate(null);
  };

  const onUpsertSuccess = (editedRow) => {
    const groupName = isHomeGroup ? 'Home' : 'Makeup';
    const isEdit = !!rowDataToUpdate;

    addToast({
      header: `${groupName} Group successfully ${isEdit ? 'updated' : 'created'}`,
      message: isEdit
        ? 'This change has been reflected on all future occurrences.'
        : 'You can now start adding members.',
      type: 'success',
    });

    if (isEdit && editedRow?.row) updateRow(editedRow.row);
    else fetchData(1, true);
    onUpsertClose();
  };

  const onUpsertError = (error) => {
    const groupName = isHomeGroup ? 'Home' : 'Makeup';
    const isEdit = !!rowDataToUpdate;

    addToast({
      header: 'Something went wrong',
      message:
        error?.parsedMessage ||
        `${groupName} Group could not be ${isEdit ? 'updated' : 'created'} at this time. Please try again.`,
      type: 'error',
    });
    onUpsertClose();
  };

  const createButtonTitle = isHomeGroup ? 'Create Home Group' : 'Create Makeup Group';

  const deleteRow = async (id) => {
    const response = await sendGetGroupsRequest('/staff/empanelment_groups', {
      params: setRequestParams(currentPage, filters, isHomeGroup, isMakeupGroup),
    });
    const { json_data, total_count, is_last_page } = response;

    const updatedData = isHomeGroup ? homeGroupData : makeupGroupData;
    const filteredRows = updatedData.json_data.filter((r) => r.id !== id);
    const currentPageLastRow = !updatedData.is_last_page ? json_data.slice(-1) : [];

    setCurrentData({
      ...updatedData,
      json_data: [...filteredRows, ...currentPageLastRow],
      is_last_page,
      total_count,
    });
  };

  const handleDeleteButtonClick = (group) => {
    setCurrentGroup({
      ...group,
      date_time: formatShortDayTime(group.date_time),
    });
    setIsDeleteModalOpen(true);
  };

  const handleDeleteGroup = async () => {
    const groupName = isHomeGroup ? 'Home' : 'Makeup';
    try {
      await sendDeleteGroupRequest(`/staff/empanelment_groups/delete_template_group/${currentGroup.id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
      });
      setIsDeleteModalOpen(false);
      addToast({
        header: `${groupName} Group successfully deleted`,
        message: 'This group has been removed from all future occurrences.',
        type: 'success',
      });

      await deleteRow(currentGroup.id);
    } catch (err) {
      setIsDeleteModalOpen(false);
      addToast({
        header: 'Something went wrong',
        message: `${groupName} Group could not be deleted at this time. Please try again.`,
        type: 'error',
      });
    }
  };

  const handleAddMember = (group) => {
    setCurrentGroup(group);
    setIsAddMemberModalOpen(true);
  };

  const handleContinueAddMember = (member) => {
    setCurrentMember(member);
    setIsAddMemberConfirmationModalOpen(true);
    setIsAddMemberModalOpen(false);
  };

  const handleCloseAddMemberConfirmationModal = () => {
    setIsAddMemberConfirmationModalOpen(false);
    setIsAddMemberModalOpen(true);
  };

  const addMember = async () => {
    try {
      await sendAddMemberRequest('/staff/empanelment_members/update', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          empanelment_id: currentGroup.id,
          id: currentMember.membersEmpanelmentGroupId,
          member_id: currentMember.id,
          is_edit: true,
        },
      });

      setIsAddMemberConfirmationModalOpen(false);
      addToast({
        header: 'Member successfully added to Home Group',
        message: 'They have been added to all future occurrences.',
        type: 'success',
      });
      await fetchData(1, true);
    } catch (err) {
      setIsAddMemberConfirmationModalOpen(false);
      addToast({
        header: 'Something went wrong',
        message: 'Member could not be added at this time. Please try again.',
        type: 'error',
      });
    }
  };

  const groupTypeClinicalModels = empanelmentClinicalModelData?.filter(
    (model) => model.type === (isHomeGroup ? 'home' : 'makeup')
  );

  const handleCreateButtonClick = () => {
    if (isMakeupGroup) {
      setRowDataToUpdate({
        modality: modalityData.find(isModalityVirtualOnly) || {},
      });
    }

    setShowUpsertModal(true);
  };

  const isFiltersLoading = isFilterDataLoading || isGroupsLoading;

  return (
    <div data-testid={testID} className="mt-3">
      <Toasts toasts={toasts} removeToast={removeToast} className="mb-6 whitespace-pre-wrap" />
      <div className="border-b border-gray-400 pb-2 flex justify-between flex-row items-end">
        <div>
          <h2 data-testid={`title-${testID}`} className="font-body font-semibold mb-1">
            Groups Management
          </h2>
          {displayLastUpdateTime()}
        </div>
        <Button onClick={toggleFilters} classes="btn--primary max-h-12">
          <div className="flex items-center">
            Apply Filters
            <FilterBtnIcon className="text-2xl ml-2" />
          </div>
        </Button>
      </div>

      {groupsManagementPermission && showUpsertModal && isHomeGroup && (
        <UpsertHomeGroupModal
          testID={testID}
          editRowId={rowDataToUpdate?.id}
          authenticityToken={authenticityToken}
          secondaryGroupTypeFeatureFlag={secondaryGroupTypeFeatureFlag}
          facilitiesData={facilitiesData}
          staffData={staffData}
          officeManagerData={officeManagerData}
          modalityData={modalityData}
          empanelmentSecondaryGroupTypeData={empanelmentSecondaryGroupTypeData}
          empanelmentClinicalModelData={groupTypeClinicalModels}
          clinicalModelData={clinicalModelData}
          defaultValues={rowDataToUpdate || undefined}
          onCancel={onUpsertClose}
          onError={onUpsertError}
          onSuccess={onUpsertSuccess}
        />
      )}
      {groupsManagementPermission && showUpsertModal && isMakeupGroup && (
        <UpsertMakeupGroupModal
          testID={testID}
          editRowId={rowDataToUpdate?.id}
          authenticityToken={authenticityToken}
          secondaryGroupTypeFeatureFlag={secondaryGroupTypeFeatureFlag}
          facilitiesData={facilitiesData}
          staffData={staffData}
          officeManagerData={officeManagerData}
          modalityData={modalityData}
          empanelmentSecondaryGroupTypeData={empanelmentSecondaryGroupTypeData}
          empanelmentClinicalModelData={groupTypeClinicalModels}
          clinicalModelData={clinicalModelData}
          defaultValues={rowDataToUpdate || undefined}
          onCancel={onUpsertClose}
          onError={onUpsertError}
          onSuccess={onUpsertSuccess}
        />
      )}

      <AppliedFilters currentlyAppliedFilters={filtersArray} isLoading={isFiltersLoading} />
      {showFilters && (
        <AvailableFilters
          currentlyAppliedFilters={filters}
          setFilter={setFilter}
          activeTab={activeTab.key}
          availabilityData={AVAILABILITY_DATA}
          daysData={daysData}
          empanelmentClinicalModelData={groupTypeClinicalModels}
          facilitiesData={facilitiesData}
          hoursData={hoursData}
          modalityData={modalityData}
          officeManagerData={officeManagerData}
          staffData={staffData}
          statesData={statesData}
          isLoading={isFiltersLoading}
          testID={testID}
        />
      )}
      <div className="mt-4">
        <div className="flex items-end justify-between border-b border-blue-300 mb-6">
          <Tabs activeTab={activeTab} tabs={GroupTabData} onChange={tabChange} testID={testID} isBorder={false} />
          {groupsManagementPermission ? (
            <Button onClick={handleCreateButtonClick} classes="mt-2 mb-2" testID={`createGroup-${testID}`} isPrimary>
              {createButtonTitle}
            </Button>
          ) : null}
        </div>
        {!isGroupsLoading ? (
          <Toasts toasts={distanceToasts} removeToast={removeDistanceToast} className="mb-6" />
        ) : null}
        {!isGroupsLoading && currentDataModel.json_data ? (
          <div id="empanelment_groups">
            <Table
              stickyHeader
              stickyActionColumn
              enableHidingShadowInStickyColumn
              enableActionsColumn={groupsManagementPermission}
              activeTab={activeTab.key}
              authenticityToken={authenticityToken}
              currentPage={currentDataModel.current_page}
              tableType="managementGroupsTable"
              tableColumnHeaders={
                isHomeGroup
                  ? GROUP_TABLE_HEADER_DATA.HOME_GROUP_HEADERS(groupsManagementPermission)
                  : GROUP_TABLE_HEADER_DATA.MAKEUP_GROUP_HEADERS(groupsManagementPermission)
              }
              tableRows={currentDataModel.json_data}
              pageChange={fetchData}
              isLastPage={currentDataModel.is_last_page}
              totalCountRecords={currentDataModel.total_count}
              onClickEditRow={onClickEditRow}
              updateRows={(_updateType, newRow) => {
                const updatedHomeGroupData = { ...homeGroupData };
                const updatedMakeupGroupData = { ...makeupGroupData };

                updatedHomeGroupData.json_data = updatedHomeGroupData.json_data.map((r) =>
                  r.id !== newRow.id ? r : newRow
                );
                updatedMakeupGroupData.json_data = updatedMakeupGroupData.json_data.map((r) =>
                  r.id !== newRow.id ? r : newRow
                );

                setHomeGroupData(updatedHomeGroupData);
                setMakeupGroupData(updatedMakeupGroupData);

                tabChange(activeTab);
              }}
              testID={testID}
              secondaryGroupTypeFeatureFlag={secondaryGroupTypeFeatureFlag}
              onDeleteButtonClick={handleDeleteButtonClick}
              onAddButtonClick={handleAddMember}
              loading={isGroupsLoading}
            />
          </div>
        ) : (
          <div data-testid={`noData-${testID}`} className="no-data-loader">
            <CircularProgress />
          </div>
        )}
      </div>
      {currentGroup && (
        <>
          <ManagementGroupDeleteModal
            isOpen={isDeleteModalOpen}
            groupToDelete={currentGroup}
            isHomeGroup={isHomeGroup}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={handleDeleteGroup}
            isLoading={isDeleteGroupLoading}
          />
          <AddMemberModal
            isOpen={isAddMemberModalOpen}
            onClose={() => setIsAddMemberModalOpen(false)}
            onMemberChange={handleContinueAddMember}
            type="Home Group"
            group={{
              date_time: currentGroup.date_time,
              counselor_name: currentGroup.counselor_name,
              location: currentGroup.location,
            }}
          />
          <AddMemberConfirmationModal
            isOpen={isAddMemberConfirmationModalOpen}
            group={currentGroup}
            onClose={handleCloseAddMemberConfirmationModal}
            onConfirm={addMember}
            isLoading={isAddMemberLoading}
            member={currentMember?.name}
          />
        </>
      )}
    </div>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  lastModifiedTime: PropTypes.string.isRequired,
  groupSchedulingEnabled: PropTypes.bool.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  groupsManagementPermission: PropTypes.bool.isRequired,
};

export default Main;
