import React from 'react';
import PropTypes from 'prop-types';
import GroupAppointments from './GroupAppointments';
import IndividualAppointments from './IndividualAppointments';

function ScheduledTab({ filters, onLoading }) {
  return (
    <div className="mt-5 gap-10" data-testid="scheduled-tab">
      <GroupAppointments filters={filters} onLoading={onLoading} />
      <IndividualAppointments filters={filters} onLoading={onLoading} />
    </div>
  );
}

ScheduledTab.propTypes = {
  filters: PropTypes.object.isRequired,
  onLoading: PropTypes.func,
};

ScheduledTab.defaultProps = {
  onLoading: null,
};

export default ScheduledTab;
