import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import { IoClose } from 'react-icons/io5';
import classNames from 'classnames';

function FilterPill({ filterType, label, onRemove, isLoading }) {
  return (
    <div
      data-testid="filter-pill"
      className={classNames(
        'border rounded-3xl border-yellow-400 bg-yellow-100 flex flex-row justify-between items-center',
        { 'opacity-50': isLoading }
      )}
    >
      <div className="font-semibold border-r border-yellow-400 px-5 py-1">
        {filterType ? `${startCase(filterType)}: ` : ''}
        {label}
      </div>
      <IoClose
        data-testid="filter-pill-close"
        onClick={!isLoading ? onRemove : null}
        className={classNames('text-2xl font-bold m-3', { 'cursor-pointer': !isLoading })}
      />
    </div>
  );
}

FilterPill.propTypes = {
  filterType: PropTypes.string,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

FilterPill.defaultProps = {
  filterType: null,
  isLoading: false,
};

export default FilterPill;
