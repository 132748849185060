/* eslint-disable import/prefer-default-export */
import { produce } from 'immer';
import { dateDifferenceInDaysAlgorithm } from './memberDetailsAlgorithms';

export function updateStatuses(medicalSummary) {
  return produce(medicalSummary, (draft) => {
    draft.componentData
      .find(({ key }) => key === 'rx_data')
      ?.items.forEach((item) => {
        if (item.key !== 'rx_details') return;

        const rx_status = item.value.find(({ key }) => key === 'rx_status');
        if (!rx_status) return;

        const end_date = rx_status.subtext?.find((subtext) => subtext.value.variables?.end_date?.parameters?.date)
          ?.value.variables.end_date.parameters.date;

        const expiresIn = dateDifferenceInDaysAlgorithm({ to: end_date }, false);
        if (!expiresIn) return;

        if (expiresIn < 0) {
          rx_status.value = 'Inactive Rx';
          rx_status.valueOptions.color = 'ERROR';

          const expiresInText = rx_status.subtext?.find(({ value }) => value.template?.startsWith('Expires in'))?.value;
          if (expiresInText) {
            expiresInText.template = 'Expired {{expires_in}} days ago';
          }
        }
      });
  });
}

export function filterExpiredRx(medicalSummary) {
  const hasActiveRx = medicalSummary.componentData
    .find(({ key }) => key === 'rx_data')
    ?.items.some((item) => {
      if (item.key !== 'rx_details') return false;

      const rx_status = item.value.find(({ key }) => key === 'rx_status');
      return rx_status?.value === 'Active Rx';
    });

  // If there are no active Rx, return the medical summary as is
  // because it is a recent Rx
  if (!hasActiveRx) return medicalSummary;

  return produce(medicalSummary, (draft) => {
    const rx_data = draft.componentData.find(({ key }) => key === 'rx_data');

    rx_data.items = rx_data?.items.filter((item) => {
      if (item.key !== 'rx_details') return true;

      const rx_status = item.value.find(({ key }) => key === 'rx_status');
      return rx_status?.value === 'Active Rx';
    });
  });
}

export function prepareMedicalSummary(medicalSummary) {
  if (!medicalSummary) return null;
  const updatedMedicalSummary = updateStatuses(medicalSummary);
  return filterExpiredRx(updatedMedicalSummary);
}
