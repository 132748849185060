import React from 'react';
import PropTypes from 'prop-types';
import { PiLockSimple } from 'react-icons/pi';
import Accordion from '../../shared/Accordion';
import HealthieForm from '../../documentation/HealthieForm';
import { getDateTime, prepareNoteReadModeProps } from './helpers';
import NoteReadMode from '../../documentation/note_read_mode_view/NoteReadMode';

function ChartingNote({
  note,
  handleChartingNoteSubmit,
  handleHealthieFormUpdate,
  setIsNoteUpdating,
  isUpdateChartingNoteLoading,
  states,
  smartPhrases,
  isFullWidth,
  getAccordionActions,
  isEditingDisabled,
  onChange,
}) {
  return (
    <Accordion
      key={note.id}
      title={note.name}
      description={`Added by ${note.filler?.name} on ${getDateTime(note.createdAt)}`}
      actions={
        !note.hasPermissionToEdit || note.lockedAt ? (
          <PiLockSimple className="text-lg text-gray-500" />
        ) : (
          getAccordionActions(note.id, note.name)
        )
      }
    >
      {!note.hasPermissionToEdit || note.lockedAt ? (
        <NoteReadMode note={prepareNoteReadModeProps(note)} isFullWidthSet={isFullWidth} />
      ) : (
        <HealthieForm
          formId={note.id}
          formAnswers={note.formAnswers}
          onSubmit={() => handleChartingNoteSubmit({ id: note.id, name: note.name })}
          onUpdate={handleHealthieFormUpdate}
          onChange={onChange}
          setIsNoteUpdating={setIsNoteUpdating}
          isVertical={!isFullWidth}
          disabled={isUpdateChartingNoteLoading || isEditingDisabled}
          states={states}
          smartPhrases={smartPhrases}
        />
      )}
    </Accordion>
  );
}

ChartingNote.propTypes = {
  note: PropTypes.object.isRequired,
  handleChartingNoteSubmit: PropTypes.func.isRequired,
  handleHealthieFormUpdate: PropTypes.func.isRequired,
  setIsNoteUpdating: PropTypes.func.isRequired,
  isUpdateChartingNoteLoading: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  smartPhrases: PropTypes.array.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  getAccordionActions: PropTypes.func.isRequired,
  isEditingDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

ChartingNote.defaultProps = {
  isEditingDisabled: false,
  onChange: null,
};

export default ChartingNote;
