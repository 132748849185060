import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

import Table from '../../../Table';
import AddMemberToAppointment from './AddMemberToAppointment';
import { TAB_IDS, TABLE_HEADERS } from './constants';
import useAppointments from './useAppointments';
import { OptionsPropTypes } from '../shared/types';

const testID = 'appointmentsMainEmp';
function BookAppointmentTab({
  authenticityToken,
  secondaryGroupTypeFeatureFlag,
  appointmentFinderMemberAssignmentPermission,
  addToast,
  filters,
  reasons,
  onLoading,
}) {
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const { isLoading, currentPage, currentData, decreaseAvailableSeats, fetchData, handleSort, sortedBy } =
    useAppointments(filters);

  useEffect(
    () => onLoading && onLoading('bookAppointment', TAB_IDS.BOOK_APPOINTMENT, isLoading),
    [onLoading, isLoading]
  );

  const onAddButtonClick = (row) => {
    setSelectedAppointment({
      id: row.id,
      scheduledAt: row.scheduled_at,
      duration: row.duration,
      locations: row.locations,
      appointmentType: row.appointment_type,
      providers: row.providers,
      isIndividual: row.is_individual,
    });
  };

  const closeAddModal = () => setSelectedAppointment(null);

  const onAddSuccess = () => {
    addToast({
      header: 'Member successfully added to appointment',
      type: 'success',
    });

    decreaseAvailableSeats(selectedAppointment.id);
    closeAddModal();
  };

  const onAddError = (error) => {
    addToast({
      header: 'Something went wrong',
      message: error?.parsedMessage ?? 'Member could not be added at this time. Please try again.',
      type: 'error',
    });

    closeAddModal();
  };

  return (
    <div className="mt-4" data-testid="book-appointment-tab">
      {appointmentFinderMemberAssignmentPermission && (
        <AddMemberToAppointment
          authenticityToken={authenticityToken}
          selectedAppointment={selectedAppointment}
          onClose={closeAddModal}
          onContinue={onAddSuccess}
          onError={onAddError}
          reasons={reasons}
        />
      )}
      {isLoading && !currentData?.json_data ? (
        <div data-testid={`noData-${testID}`} className="no-data-loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="border-b border-blue-300 mb-6 shadow-md border-r-2">
          <Table
            tableType="appointmentsTable"
            enableActionsColumn={appointmentFinderMemberAssignmentPermission}
            showShadow={false}
            authenticityToken={authenticityToken}
            secondaryGroupTypeFeatureFlag={secondaryGroupTypeFeatureFlag}
            currentPage={currentPage}
            tableColumnHeaders={TABLE_HEADERS(appointmentFinderMemberAssignmentPermission)}
            tableRows={currentData.json_data}
            pageChange={fetchData}
            isLastPage={currentData.is_last_page}
            totalCountRecords={currentData.total_count}
            onAddButtonClick={onAddButtonClick}
            displayPaginationStartingElementIndex={false}
            headerButtonClick={handleSort}
            currentSort={sortedBy}
            loading={isLoading}
            testID={testID}
          />
        </div>
      )}
    </div>
  );
}

BookAppointmentTab.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  appointmentFinderMemberAssignmentPermission: PropTypes.bool.isRequired,
  addToast: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  reasons: OptionsPropTypes.isRequired,
  onLoading: PropTypes.func,
};

BookAppointmentTab.defaultProps = {
  onLoading: null,
};

export default BookAppointmentTab;
