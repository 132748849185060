import React from 'react';
import PropTypes from 'prop-types';
import { FILTER_TYPES } from './constants';
import Filters from '../../Filters';
import FiltersColumn from '../../FiltersColumn';
import FilterTextField from '../../FilterTextField';
import Loader from '../../shared/Loader';

function EncountersFilters({
  currentlyAppliedFilters,
  testID,
  setFilter,
  stateData,
  facilityData,
  groupDayData,
  memberData,
  dosageData,
  statusData,
  txData,
  payerData,
  officeManagerData,
  counselorData,
  providerData,
  isLoading,
}) {
  const getStateChangeHandler = (type) => (selectedItem) => setFilter(type, selectedItem);
  const getTextChangeHandler = (type) => (value) => setFilter(type, value);

  return (
    <div className="border border-gray-300 rounded bg-white bg-opacity-50 grid grid-cols-3 divide-x divide-gray-300 relative">
      {isLoading && <Loader />}
      <FiltersColumn>
        <Filters
          label="State"
          type={FILTER_TYPES.state}
          data={stateData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.state)}
          testID={`state-${testID}`}
          placeholder="Search State"
          value={currentlyAppliedFilters[FILTER_TYPES.state]}
        />
        <Filters
          label="Facility"
          type={FILTER_TYPES.facility}
          data={facilityData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.facility)}
          testID={`facility-${testID}`}
          placeholder="Search Facility"
          value={currentlyAppliedFilters[FILTER_TYPES.facility]}
        />
        <Filters
          label="Group Day"
          type={FILTER_TYPES.groupDay}
          data={groupDayData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.groupDay)}
          testID={`group-day-${testID}`}
          placeholder="Search Group Day"
          value={currentlyAppliedFilters[FILTER_TYPES.groupDay]}
        />
        <FilterTextField
          name="member-search-input"
          id="member-search-input"
          label="Member"
          placeholder="Search Members by Name or MRN"
          value={currentlyAppliedFilters[FILTER_TYPES.member]}
          onChange={getTextChangeHandler(FILTER_TYPES.member)}
        />
        <Filters
          label="Member"
          type={FILTER_TYPES.member}
          data={memberData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.member)}
          testID={`member-${testID}`}
          placeholder="Search Member"
          value={currentlyAppliedFilters[FILTER_TYPES.member]}
        />
      </FiltersColumn>
      <FiltersColumn>
        <Filters
          label="Dosage"
          type={FILTER_TYPES.dosage}
          data={dosageData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.dosage)}
          testID={`dosage-${testID}`}
          placeholder="Search Dosage"
          value={currentlyAppliedFilters[FILTER_TYPES.dosage]}
        />
        <Filters
          label="Status"
          type={FILTER_TYPES.status}
          data={statusData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.status)}
          testID={`status-${testID}`}
          placeholder="Search Status"
          value={currentlyAppliedFilters[FILTER_TYPES.status]}
        />
        <Filters
          label="Tx (Weeks)"
          type={FILTER_TYPES.tx}
          data={txData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.tx)}
          testID={`tx-${testID}`}
          placeholder="Search Tx"
          value={currentlyAppliedFilters[FILTER_TYPES.tx]}
        />
        <Filters
          label="Payer"
          type={FILTER_TYPES.payer}
          data={payerData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.payer)}
          testID={`payer-${testID}`}
          placeholder="Search Payer"
          value={currentlyAppliedFilters[FILTER_TYPES.payer]}
        />
      </FiltersColumn>
      <FiltersColumn>
        <Filters
          label="Office Manager"
          type={FILTER_TYPES.officeManager}
          data={officeManagerData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.officeManager)}
          testID={`office-manager-${testID}`}
          placeholder="Search Office Manager"
          value={currentlyAppliedFilters[FILTER_TYPES.officeManager]}
        />
        <Filters
          label="Counselor"
          type={FILTER_TYPES.counselor}
          data={counselorData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.counselor)}
          testID={`counselor-${testID}`}
          placeholder="Search Counselor"
          value={currentlyAppliedFilters[FILTER_TYPES.counselor]}
        />
        <Filters
          label="Provider"
          type={FILTER_TYPES.provider}
          data={providerData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.provider)}
          testID={`provider-${testID}`}
          placeholder="Search Provider"
          value={currentlyAppliedFilters[FILTER_TYPES.provider]}
        />
      </FiltersColumn>
    </div>
  );
}

EncountersFilters.propTypes = {
  currentlyAppliedFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
  stateData: PropTypes.array,
  facilityData: PropTypes.array,
  groupDayData: PropTypes.array,
  memberData: PropTypes.array,
  dosageData: PropTypes.array,
  statusData: PropTypes.array,
  txData: PropTypes.array,
  payerData: PropTypes.array,
  officeManagerData: PropTypes.array,
  counselorData: PropTypes.array,
  providerData: PropTypes.array,
  isLoading: PropTypes.bool,
};

EncountersFilters.defaultProps = {
  stateData: [],
  facilityData: [],
  groupDayData: [],
  memberData: [],
  dosageData: [],
  statusData: [],
  txData: [],
  payerData: [],
  officeManagerData: [],
  counselorData: [],
  providerData: [],
  isLoading: false,
};

export default EncountersFilters;
