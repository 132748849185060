import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import HealthieInput from './HealthieInput';
import {
  getSchema,
  getDefaultAnswer,
  getUpdatedAnswers,
  getUpdatedAnswer,
  getAnswerPillVariant,
  isMulti,
  isProblemList,
  isConditionalQuestionVisibleChain,
} from './helpers/healthieFormHelpers';
import { FormAnswerPropType, SmartPhrasesPropType } from './helpers/types';
import AnswerPill from './AnswerPill';
import Input from '../shared/Input';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import Button from '../shared/Button';
import { DEFAULT_DATE_FORMAT } from '../../helpers/DateFormatter';

const DEBOUNCE_MS = 20000;
const APPLY_TO_ALL_INPUT_NAME_SUFFIX = 'Checkbox';

const geAnswerPillDate = (date) => {
  const [timeWithOffset] = convertToSupportedTimeZone(date);
  return timeWithOffset.format(DEFAULT_DATE_FORMAT);
};

function HealthieForm({
  formId,
  formAnswers,
  onSubmit,
  isVertical,
  onUpdate,
  onChange,
  isNoteUpdating,
  setIsNoteUpdating,
  disabled,
  states,
  onApplyAnswerToAll,
  smartPhrases,
  selectedFormAnswerId,
}) {
  const fullFormAnswers = formAnswers?.filter((formAnswer) => formAnswer?.customModule);

  const defaultValues = fullFormAnswers?.reduce(
    (prevValue, answer) => ({
      ...prevValue,
      [answer.customModule?.id]: getDefaultAnswer(answer),
    }),
    {}
  );

  const schema = object(
    fullFormAnswers?.reduce(
      (prevValue, answer) => ({
        ...prevValue,
        [answer.customModule?.id]: getSchema(answer, formAnswers),
      }),
      {}
    )
  );

  const defaultApplyAnswerToAllValues = {
    ...fullFormAnswers?.reduce(
      (prevValue, answer) => ({
        ...prevValue,
        [`${answer.customModule?.id}${APPLY_TO_ALL_INPUT_NAME_SUFFIX}`]: false,
      }),
      {}
    ),
    all: false,
  };

  const { handleSubmit, control, getValues, setError, formState } = useForm({
    values: defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    control: applyAnswerToAllControl,
    watch: applyAnswerToAllWatch,
    setValue: setApplyAnswerToAllValue,
    reset: resetApplyAnswerToAll,
  } = useForm({
    values: defaultApplyAnswerToAllValues,
  });

  const inputValues = useWatch({ control });
  const prevInputValues = useRef(inputValues);
  const questionRef = useRef();
  const isInitialChange = useRef(true);
  const applyAnswerToAllValues = applyAnswerToAllWatch();

  const handleFormSubmit = (data) => onSubmit(data);

  const updateForm = useCallback(async () => {
    const editedInputs = Object.entries(inputValues)
      .filter(([key, value]) => JSON.stringify(value) !== JSON.stringify(prevInputValues.current[key]))
      .map(([key]) => key);

    try {
      await onUpdate(formId, getUpdatedAnswers(inputValues));
      prevInputValues.current = inputValues;
    } catch (e) {
      editedInputs.forEach((editedInput) =>
        setError(editedInput, { message: 'There was an error when saving note. Please try again later' })
      );
    }
  }, [formId, onUpdate, setError, inputValues]);

  useEffect(() => {
    if (isInitialChange.current) {
      isInitialChange.current = false;
      return;
    }

    if (onChange) onChange(formId, getUpdatedAnswers(inputValues), formState.isDirty);
  }, [formId, onChange, inputValues, formState.isDirty]);

  useEffect(() => {
    if (!onUpdate || !formState.isDirty) {
      if (setIsNoteUpdating) setIsNoteUpdating(false);
      return;
    }

    if (setIsNoteUpdating) setIsNoteUpdating(true);

    // Debounce
    const timeout = setTimeout(() => {
      if (setIsNoteUpdating) setIsNoteUpdating(false);
      updateForm();
    }, DEBOUNCE_MS);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [inputValues, updateForm, setIsNoteUpdating, onUpdate, formState.isDirty]);

  useEffect(() => {
    questionRef.current?.scrollIntoView();
  }, []);

  const filterConditionalFormAnswers = () => {
    const answers = fullFormAnswers.map((formAnswer) => ({
      ...formAnswer,
      answer: inputValues[formAnswer.customModule.id],
    }));

    return answers.filter((answer) => isConditionalQuestionVisibleChain(answer, answers));
  };

  const handleApplyToAllClick = () => {
    const answers = Object.entries(inputValues)
      .filter(([key]) => applyAnswerToAllValues[`${key}${APPLY_TO_ALL_INPUT_NAME_SUFFIX}`])
      .reduce(
        (prevValue, [key, value]) => ({
          ...prevValue,
          [key?.replace(APPLY_TO_ALL_INPUT_NAME_SUFFIX, '')]: getUpdatedAnswer(value),
        }),
        {}
      );
    onApplyAnswerToAll(answers, formId);
  };

  const handleCheckboxChange = ({ target }) => {
    if (target.name !== 'all') {
      const visibleFormAnswersLength = filterConditionalFormAnswers()?.length;
      const checkedCheckboxesLength = Object.entries({
        ...applyAnswerToAllValues,
        [target.name]: target.checked,
      }).filter(([key, value]) => key !== 'all' && value)?.length;
      const allCheckboxValue = visibleFormAnswersLength === checkedCheckboxesLength;

      setApplyAnswerToAllValue('all', allCheckboxValue);
      return;
    }

    if (target.name === 'all' && target.checked) {
      Object.keys(applyAnswerToAllValues).forEach((key) => setApplyAnswerToAllValue(key, true));
      return;
    }

    resetApplyAnswerToAll();
  };

  if (!Object.keys(getValues()).length) {
    return null;
  }

  const isApplyAnswerToAllSelected = Object.values(applyAnswerToAllValues).filter((value) => value).length;

  return (
    <>
      {onApplyAnswerToAll && (
        <div className="flex items-center justify-between pb-4 border-gray-300 border-b">
          <Controller
            control={applyAnswerToAllControl}
            name="all"
            render={({ field }) => (
              <Input
                {...field}
                value={field.name}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleCheckboxChange(e);
                }}
                type="checkbox"
                option="Select all"
                disabled={disabled || isNoteUpdating}
              />
            )}
          />
          <Button
            isSecondary
            onClick={handleApplyToAllClick}
            disabled={disabled || isNoteUpdating || !isApplyAnswerToAllSelected}
          >
            Apply selected answer to group
          </Button>
        </div>
      )}
      <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          {filterConditionalFormAnswers().map((formAnswer) => {
            const dynamicQuestionRef = selectedFormAnswerId === formAnswer.id ? { ref: questionRef } : {};
            const {
              optionsArray: options,
              modType: type,
              required,
              id: customModuleId,
            } = formAnswer?.customModule || {};

            const answerPillVariant = getAnswerPillVariant(formAnswer.extensions, formAnswer?.isEdited);

            return (
              <div
                key={customModuleId}
                className="py-4 border-gray-300 border-b last:border-b-0"
                {...dynamicQuestionRef}
              >
                <div className="flex gap-2">
                  {onApplyAnswerToAll && (
                    <Controller
                      control={applyAnswerToAllControl}
                      name={`${customModuleId}${APPLY_TO_ALL_INPUT_NAME_SUFFIX}`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleCheckboxChange(e);
                          }}
                          value={field.name}
                          checked={field.value}
                          type="checkbox"
                          disabled={disabled || isNoteUpdating}
                        />
                      )}
                    />
                  )}
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={customModuleId}
                      render={({ field, fieldState: { error } }) => (
                        <HealthieInput
                          {...field}
                          name={customModuleId}
                          label={formAnswer.label}
                          options={options}
                          type={type}
                          required={required}
                          isVertical={isVertical}
                          error={error?.message}
                          disabled={disabled}
                          isMulti={isMulti(formAnswer)}
                          isProblemList={isProblemList(formAnswer)}
                          states={states}
                          smartPhrases={smartPhrases}
                        />
                      )}
                    />
                  </div>
                </div>
                {answerPillVariant ? (
                  <div className="pt-1 flex gap-2">
                    {onApplyAnswerToAll && <div className="w-5" />}
                    {!isVertical && <div className="flex-1" />}
                    <div className="flex-1">
                      <AnswerPill
                        variant={answerPillVariant}
                        date={geAnswerPillDate(formAnswer?.lastChartingNoteCreatedAt)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </form>
    </>
  );
}

HealthieForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  formAnswers: PropTypes.arrayOf(FormAnswerPropType).isRequired,
  isVertical: PropTypes.bool,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  isNoteUpdating: PropTypes.bool,
  setIsNoteUpdating: PropTypes.func,
  disabled: PropTypes.bool,
  states: PropTypes.objectOf(PropTypes.string),
  onApplyAnswerToAll: PropTypes.func,
  smartPhrases: SmartPhrasesPropType,
  selectedFormAnswerId: PropTypes.string,
};

HealthieForm.defaultProps = {
  isVertical: true,
  onUpdate: null,
  onChange: null,
  isNoteUpdating: false,
  setIsNoteUpdating: null,
  disabled: false,
  states: null,
  onSubmit: () => {},
  onApplyAnswerToAll: null,
  smartPhrases: null,
  selectedFormAnswerId: null,
};

export default HealthieForm;
