// V2 Date Formatter

// https://momentjs.com/
import moment from 'moment';
// eslint-disable-next-line import/named
import { getTimeZone } from './TimezoneHelper';

const DEFAULT_DATE_FORMAT = 'M/D/YYYY';
const DEFAULT_DATE_WITH_WEEKDAY_FORMAT = `ddd, ${DEFAULT_DATE_FORMAT}`;
const DEFAULT_TIME_FORMAT = 'h:mm A';
const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`;

/*
  mdyyyy will not parse a date string without a timestamp correctly due to offsetting
  the given date to UTC and then converting that back to local time. Use mdyyyyFromDate
  if you will only be given a datestring without a timestamp.
*/
function mdyyyy(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).format('M/D/YYYY');

  return formattedDate;
}

function mdyyyyTimeFormat(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).format('M/D/YYYY h:mm A');

  return formattedDate;
}

/*
  mdyyFromDate will not necessarily parse a datetime correctly because it will first
  convert the DateTime to utc before stripping the formatted date. If date in UTC is expected
  to be different than local date, use mmddyy
*/
function mdyyyyFromDate(dateString) {
  if (!dateString) {
    return '';
  }

  const formattedDate = moment(dateString).utc(true).format('M/D/YYYY');

  return formattedDate;
}

function mdyyyyUTC(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).utc().format('M/D/YYYY');

  return formattedDate;
}
/**
 *
 * @param {*} dateString - date_time stamp
 * @returns string - example Feb 07 11:05 AM
 */
function mmmddTimeFormat(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).format('MMM DD h:mm A');

  return formattedDate;
}

/**
 *
 * @param {*} dateString - date_time stamp
 * @returns string - example Friday, February, 15th, 2023
 */
function dayMonthDateYear(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).format('dddd, MMMM, Do, YYYY');

  return formattedDate;
}

/**
 *
 * @param {*} dateString - date_time stamp
 * @returns string - example Wed, Feb 15
 */
function dayAbbrevMonthAbbrevDate(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).format('ddd, MMM DD');

  return formattedDate;
}

/**
 *
 * @param {*} dateString - date_time stamp
 * @returns string - example 2:21 PM
 */
function hourMinTimezone(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const timezone = getTimeZone(dateString);

  // TODO: add time zone
  const formattedDate = `${moment(date).format('h:mm A')} ${timezone}`;

  return formattedDate;
}

// Takes a date string and finds the number of days between that date and today
function daysBetween(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const today = new Date().setUTCHours(0, 0, 0, 0);

  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = today - date;

  // Convert back to days and return
  return Math.floor(differenceMs / ONE_DAY);
}

// Set the time of a date to the another date
function setDateTime(dateString, timeString) {
  if (!dateString || !timeString) return null;
  const date = moment(dateString);
  const time = moment(timeString);

  return date
    .clone()
    .hours(time.hours())
    .minutes(time.minutes())
    .seconds(time.seconds())
    .milliseconds(time.milliseconds());
}

// Convert days between to a displayable string
function daysOverdue(dateString) {
  const dayDiff = daysBetween(dateString);
  if (dayDiff <= 0) {
    return null;
  }
  if (dayDiff === 1) {
    return `${dayDiff} day overdue`;
  }

  return `${dayDiff} days overdue`;
}

function monthDateYearString(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  const formattedDate = moment(date).format('MMMM D, YYYY');

  return formattedDate;
}

function formatDateRange(fromDate, toDate) {
  if (!fromDate && !toDate) return undefined;
  if (fromDate && !toDate) return `From ${mdyyyy(fromDate)}`;
  if (!fromDate && toDate) return `To ${mdyyyy(toDate)}`;
  return `${mdyyyy(fromDate)} - ${mdyyyy(toDate)}`;
}

function formatTimeRange(fromTime, toTime) {
  if (!fromTime && !toTime) return undefined;
  if (fromTime && !toTime) return `From ${moment(fromTime).format(DEFAULT_TIME_FORMAT)}`;
  if (!fromTime && toTime) return `To ${moment(toTime).format(DEFAULT_TIME_FORMAT)}`;
  return `${moment(fromTime).format(DEFAULT_TIME_FORMAT)} - ${moment(toTime).format(DEFAULT_TIME_FORMAT)}`;
}

// eslint-disable-next-line import/prefer-default-export
export {
  mdyyyy,
  mdyyyyTimeFormat,
  mdyyyyFromDate,
  mdyyyyUTC,
  mmmddTimeFormat,
  dayMonthDateYear,
  dayAbbrevMonthAbbrevDate,
  hourMinTimezone,
  daysBetween,
  daysOverdue,
  setDateTime,
  monthDateYearString,
  formatDateRange,
  formatTimeRange,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_WITH_WEEKDAY_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATETIME_FORMAT,
};
