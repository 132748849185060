import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight as BreadcrumbsIcon } from 'react-icons/fa6';
import { CircularProgress } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { PageTabs, TAB_IDS } from './constants';
import useToasts from '../../../shared/hooks/use-toasts';
import Button from '../../../shared/Button';
import Toasts from '../../../shared/Toasts';
import Table from '../../../Table';
import { TABLE_HEADERS } from '../list/GroupAppointments';
import Tabs from '../../../Tabs';
import { GroupAppointmentSnakeCasePropTypes } from '../shared/types';
import PanelSummaryTab from './PanelSummaryTab';
import SnapshotTab from './SnapshotTab';
import AttendanceTab from './AttendanceTab';
import GroupChartingNotes from '../../../documentation/GroupChartingNotes';
import AppointmentActions from './AppointmentActions';
import { CANCELLED_APPOINTMENT_STATUS, ATTENDED_ATTENDANCE_STATUS } from '../shared/constants';
import { AppointmentsURLsController } from '../list/useAppointmentsURLs';
import { convertObjKeysToCamelCase, openLinkInNewTab } from '../../../../helpers/utils';
import useChartingNoteExternalIds from '../../../documentation/useChartingNoteExternalIds';
import { mapFormToAppointment } from './helpers';
import { SmartPhrasesPropType } from '../../../documentation/helpers/types';

const testID = 'groupAppointmentDetailsMain';
function Main({
  canUserManageAppointment,
  canUserManageOtherAppointments,
  canUserManageChartingNotes,
  appointmentFinderUrl,
  authenticityToken,
  appointment: appointmentSnakeCase,
  states,
  smartPhrases,
}) {
  const { toasts, addToast, removeToast } = useToasts();
  const [appointment, setAppointment] = useState(() => {
    const camelCaseAppointment = convertObjKeysToCamelCase(appointmentSnakeCase, ['variables']);
    return {
      ...camelCaseAppointment,
      members: (camelCaseAppointment.members || []).sort((a, b) => {
        const firstNameComparison = a.firstName.localeCompare(b.firstName);
        if (firstNameComparison !== 0) return firstNameComparison;
        return a.lastName.localeCompare(b.lastName);
      }),
    };
  });
  const [appointmentUpdateInProgress, setAppointmentUpdateInProgress] = useState(false);
  const {
    chartingNoteExternalIds,
    isLoading: isExternalIdsLoading,
    fetchChartingNoteExternalIds,
  } = useChartingNoteExternalIds({
    appointmentOccurrenceId: appointment.id,
  });
  const membersCount = appointment.members?.length ?? 0;

  const attendedMembers = useMemo(
    () => appointment.members?.filter((member) => member.attendance.status === ATTENDED_ATTENDANCE_STATUS.value) ?? [],
    [appointment]
  );
  const membersWithLegacyNotes = useMemo(
    () =>
      appointment.members?.filter(
        (member) =>
          member.chartingNotes.find((chartingNote) => chartingNote.legacyId) &&
          !attendedMembers.map((attendedMember) => attendedMember.id).includes(member.id)
      ) || [],
    [appointment, attendedMembers]
  );

  const [activeTab, setTab] = useState(PageTabs[0]);

  const isAppointmentCancelled = appointment.status === CANCELLED_APPOINTMENT_STATUS;
  const hasAppointmentMembers = membersCount > 0;
  const canJoinMeeting = !!appointment.videoMeetingHostUrl;

  const onAttendanceUpdate = (formItems) => {
    setAppointmentUpdateInProgress(true);
    setAppointment(mapFormToAppointment(appointment, formItems));
    fetchChartingNoteExternalIds();
    setAppointmentUpdateInProgress(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center py-5 justify-between border-b border-gray-400">
        <div className="flex items-center gap-2">
          <a href={appointmentFinderUrl} target="_top" className="font-bold text-3xl underline text-teal-700">
            Appointment Finder
          </a>
          <BreadcrumbsIcon className="text-gray-600" />
          <span className="font-bold text-3xl">Group Appointment</span>
        </div>
        {canUserManageOtherAppointments && (
          <div className="flex items-center gap-2">
            <AppointmentActions
              appointment={appointment}
              updateAppointment={setAppointment}
              authenticityToken={authenticityToken}
              addToast={addToast}
              isDisabled={isAppointmentCancelled}
              appointmentFinderUrl={appointmentFinderUrl}
              canUserManageAppointment={canUserManageAppointment}
            />
            <Button
              isPrimary
              onClick={() => openLinkInNewTab(appointment.videoMeetingHostUrl)}
              disabled={isAppointmentCancelled || !canJoinMeeting}
            >
              Join meeting
            </Button>
          </div>
        )}
      </div>

      <Toasts toasts={toasts} removeToast={removeToast} className="my-6" />

      <div className="flex flex-col gap-6 py-5">
        <div className="flex flex-col gap-2">
          <span className="text-2xl font-bold">Appointment Details</span>
          <div className="border border-gray-400 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
            <AppointmentsURLsController appointmentFinderUrl={appointmentFinderUrl}>
              <Table
                tableType="groupAppointments"
                testID="groupAppointments"
                showShadow={false}
                enableActionsColumn={false}
                hideLoadMoreButton
                showPageNumbers={false}
                showBottomMargin={false}
                tableRows={[{ ...appointment, memberCount: membersCount }]}
                tableColumnHeaders={TABLE_HEADERS}
              />
            </AppointmentsURLsController>
          </div>
        </div>
        {!hasAppointmentMembers && (
          <div className="mt-6 flex flex-col gap-2 items-center">
            <span>There are no members assigned to this appointment.</span>
            <span>
              You can assign them in the{' '}
              <a href={appointmentFinderUrl} target="_top">
                Appointment Finder
              </a>
              .
            </span>
          </div>
        )}
        {!isAppointmentCancelled && hasAppointmentMembers && (
          <>
            <span className="text-2xl font-bold">Group Members ({membersCount})</span>
            <Tabs activeTab={activeTab} tabs={PageTabs} onChange={setTab} testID={testID} />
            <div className="flex flex-col -mt-4">
              {activeTab.id === TAB_IDS.PANEL_SUMMARY && <PanelSummaryTab appointment={appointment} />}
              {activeTab.id === TAB_IDS.SNAPSHOT && <SnapshotTab appointment={appointment} />}
              {activeTab.id === TAB_IDS.ATTENDANCE && (
                <AttendanceTab
                  appointment={appointment}
                  onUpdate={onAttendanceUpdate}
                  authenticityToken={authenticityToken}
                  addToast={addToast}
                  canUserManageAppointment={canUserManageAppointment}
                />
              )}
            </div>
            {(isExternalIdsLoading || appointmentUpdateInProgress) && (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            )}
            {!isExternalIdsLoading &&
              !appointmentUpdateInProgress &&
              (!isEmpty(attendedMembers) || !isEmpty(membersWithLegacyNotes)) && (
                <GroupChartingNotes
                  authenticityToken={authenticityToken}
                  members={attendedMembers}
                  membersWithLegacyNotes={membersWithLegacyNotes}
                  states={states}
                  appointmentOccurrenceId={appointment.id}
                  selectedChartingNoteIds={chartingNoteExternalIds || []}
                  smartPhrases={smartPhrases}
                  canUserManageChartingNotes={canUserManageChartingNotes}
                />
              )}
          </>
        )}
      </div>
    </div>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  canUserManageAppointment: PropTypes.bool.isRequired,
  canUserManageOtherAppointments: PropTypes.bool.isRequired,
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  appointmentFinderUrl: PropTypes.string.isRequired,
  appointment: GroupAppointmentSnakeCasePropTypes.isRequired,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  smartPhrases: SmartPhrasesPropType,
};

Main.defaultProps = {
  smartPhrases: null,
};

export default Main;
