import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import flattenDeep from 'lodash/flattenDeep';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { DEFAULT_TIME_FORMAT, DEFAULT_DATE_FORMAT } from '../../../helpers/DateFormatter';

export const getFormAnswerGroup = (note) => note.data.createFormAnswerGroup.formAnswerGroup;

export const getNoteIds = (notes) =>
  notes.reduce((prevNote, note) => {
    if (!note) return null;

    return {
      ...prevNote,
      [note.user.id]: note.id,
    };
  }, {});

export const getMembersAnswers = (notes, customModuleId) =>
  notes.reduce((prevNote, note) => {
    const formAnswers = note?.formAnswers?.find((answer) => answer.customModule.id === customModuleId);

    if (!formAnswers) return prevNote;

    return {
      ...prevNote,
      [note.user.id]: {
        ...formAnswers,
        formId: note.id,
      },
    };
  }, {});

const findChartingNoteAnswerById = (notes, answerId) =>
  notes
    .find((note) => note.formAnswers.find((answer) => answer.id === answerId))
    ?.formAnswers?.find((answer) => answer.id === answerId);

const getUpdatedChartingNoteAnswer = (formAnswer, newNotes) => {
  const updatedAnswer = Object.entries(formAnswer).reduce((prevFormAnswer, [currentHealthieMrn, currentNoteAnswer]) => {
    const newNoteAnswer = findChartingNoteAnswerById(newNotes, currentNoteAnswer?.id);

    return {
      ...prevFormAnswer,
      [currentHealthieMrn]: {
        ...currentNoteAnswer,
        ...newNoteAnswer,
        customModule: currentNoteAnswer?.customModule,
        isEdited: currentNoteAnswer?.isEdited || (newNoteAnswer && currentNoteAnswer?.answer !== newNoteAnswer.answer),
        lastChartingNoteCreatedAt: currentNoteAnswer?.lastChartingNoteCreatedAt,
      },
    };
  }, {});

  return {
    ...formAnswer,
    ...updatedAnswer,
  };
};

export const getUpdatedChartingNoteAnswers = (selectedNote, newNotes) =>
  selectedNote.formAnswers.map((formAnswer) => getUpdatedChartingNoteAnswer(formAnswer, newNotes));

export const getDateTime = (dateTime) => {
  if (!dateTime) return null;

  const [timeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(dateTime);
  return `${timeWithOffset.format(DEFAULT_DATE_FORMAT)}, ${timeWithOffset.format(DEFAULT_TIME_FORMAT)} ${supportedTimeZone.abbrev}`;
};

export const getApplyToAllUpdatedNotes = (memberHealthieMrns, notesToUpdate, answers) =>
  memberHealthieMrns.map((healthieMrn) => ({
    formId: notesToUpdate.ids[healthieMrn],
    formAnswers: notesToUpdate.formAnswers
      .filter((formAnswer) => formAnswer[healthieMrn])
      .map((formAnswer) => {
        const memberNoteAnswer = formAnswer[healthieMrn];
        return {
          answer: answers[memberNoteAnswer.customModule.id] || memberNoteAnswer.answer,
          custom_module_id: memberNoteAnswer.customModule.id,
          id: memberNoteAnswer.id,
          label: memberNoteAnswer.label,
        };
      }),
  }));

export const getLockedNoteIds = (notes) => notes.filter((note) => note.lockedAt).map((note) => note.id);

export const getAddendums = (notes) => {
  if (!notes?.length) return [];

  const flattenAddendums = flattenDeep(
    notes.map((note) =>
      note.addendums ? note.addendums?.map((addendum) => ({ ...addendum, chartingNoteId: note.id })) : []
    )
  );

  const groupedAddendums = groupBy(flattenAddendums, 'content');
  const formattedAddendums = [];

  Object.values(groupedAddendums).forEach((addendums) => {
    formattedAddendums.push({
      ...addendums[0],
      addedFor: addendums.map((addendum) => addendum.chartingNoteId),
    });
  });

  return formattedAddendums;
};

export const getCoSigners = (notes) => {
  if (!notes?.length) return [];

  const flattenCoSigners = flattenDeep(
    notes.map((note) =>
      note.coSigners ? note.coSigners?.map((coSigner) => ({ ...coSigner, chartingNoteId: note.id })) : []
    )
  );

  const groupedCoSigners = groupBy(flattenCoSigners, 'signedBy');
  const formattedCoSigners = [];

  Object.values(groupedCoSigners).forEach((coSigners) => {
    formattedCoSigners.push({
      ...coSigners[0],
      coSignedFor: coSigners.map((coSigner) => coSigner.chartingNoteId),
    });
  });

  return formattedCoSigners;
};

const setHealthieMrnAsObjKey = (notes, param) =>
  notes.reduce(
    (prevNotes, currentNote) => ({
      ...prevNotes,
      [currentNote.user.id]: currentNote[param],
    }),
    {}
  );

export const getLockData = (notes) => {
  const lockedNoteIds = getLockedNoteIds(notes);

  if (!lockedNoteIds.length) return null;

  return {
    lockedAt: setHealthieMrnAsObjKey(notes, 'lockedAt'),
    lockedBy: setHealthieMrnAsObjKey(notes, 'lockedBy'),
  };
};

export const getNewNoteAnswers = (currentNotes, noteId, data) => {
  const noteAnswers = currentNotes?.find((note) => note.id === noteId)?.formAnswers;
  return noteAnswers?.filter(Boolean).map(({ customModule, id, label }) => ({
    answer: data[customModule.id],
    custom_module_id: customModule.id,
    id,
    label,
  }));
};

export const getBaseNote = (notes) => {
  if (!notes?.length) return null;

  return {
    ...notes.find((note) => note?.formAnswers),
    formAnswers: uniqBy(
      notes.flatMap((note) => note?.formAnswers),
      'customModule.id'
    ),
  };
};

export const getUpdatedFormAnswersInputValues = (formAnswersInputValues, formId, inputValues, isDirty) => {
  if (!isDirty) {
    return formAnswersInputValues?.[formId]
      ? Object.entries(formAnswersInputValues)
          .filter(([prevFormId]) => prevFormId !== formId)
          .reduce(
            (prevValue, [newFormId, newInputValues]) => ({
              ...prevValue,
              [newFormId]: newInputValues,
            }),
            null
          )
      : formAnswersInputValues;
  }

  return {
    ...formAnswersInputValues,
    [formId]: inputValues,
  };
};
