import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Header from '../../shared/Header';
import PackageOptions from '../shared/PackageOptions';
import EmptyTableMessage from '../../shared_dashboard/EmptyTableMessage';
import ErrorMessage from '../../shared_dashboard/ErrorMessage';

function PackageRates(props) {
  // CACHED DATA
  const { _service } = props.labelContents || {};

  // SET DATA
  const [contents, setContents] = useState({});
  useEffect(() => setContents(props.labelContents), [props.labelContents]);

  // RENDER RATES
  const renderEmptyRatesMessages = () => {
    if (props.packageOptions || props.rateError || props.poBoxError) return <></>;
    return (
      <EmptyTableMessage
        classes="mt-3"
        text="Rates Unavailable"
        subText="Enter and save package dimensions to see available rates"
      />
    );
  };

  const renderRates = () => {
    if (!props.packageOptions || props.rateError || props.poBoxError) return <></>;
    return (
      <PackageOptions
        options={props.packageOptions}
        handleChange={(selectedLabel) => {
          const selectedService = props.packageOptions.find((element) => element?.label === selectedLabel);
          contents.service = selectedService;
          props.onSetData(contents);
        }}
      />
    );
  };

  const renderRatesErrorMessage = () => {
    if (props.rateError) {
      return (
        <ErrorMessage
          classes="mt-3"
          text="Error: No Rates"
          subTextA="The most common reasons for this error are:"
          listItems={[
            'Incorrect zip code',
            'Incomplete recipient address',
            "The package dimensions or weight exceed the restrictions set by the carriers you've activated",
            "A Flat Rate or Regional Rate package has been chosen but isn't available for this particular shipment",
            "The carrier's system is temporarily unavailable or not responding",
            'The carrier account is inactive in Shippo',
          ]}
          subTextB="Please check the dimensions and weight of your package, as well as the city/state/zip code combination, to resolve this issue. If all of that information is correct, or if you're getting that same error message for multiple different orders please submit an IT ticket."
        />
      );
    }
    return <></>;
  };

  const renderPoBoxErrorMessage = () => {
    if (props.poBoxError) {
      return (
        <ErrorMessage
          classes="mt-3"
          text="Error: No Rates"
          subTextA="The shipment is addressed to a PO Box, which is not supported"
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <Header text="Rates" withBottomMargin withNoUnderline />
      <div style={{ marginBottom: '20px' }}>
        <div>Transit times may be estimated.</div>
        {!props.isRegionalDirector && <div>If you need expedited shipping, work with your supervisor.</div>}
      </div>

      {renderEmptyRatesMessages()}
      {renderRates()}
      {renderRatesErrorMessage()}
      {renderPoBoxErrorMessage()}
    </>
  );
}

PackageRates.propTypes = {
  labelContents: PropTypes.object.isRequired,
  packageOptions: PropTypes.array.isRequired,
  onSetData: PropTypes.func.isRequired,
  rateError: PropTypes.bool.isRequired,
  poBoxError: PropTypes.bool.isRequired,
  isRegionalDirector: PropTypes.bool.isRequired,
};

export default PackageRates;
