import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@typeform/embed-react';

function TypeformWidget(props) {
  const sendMessage = async () => {
    window.ReactNativeWebView.postMessage('typeformCompleted');
  };

  return (
    <div>
      <Widget
        id={props.surveyId}
        hidden={{
          member_id: props.memberId,
          form_type: props.formType,
        }}
        style={{ width: '100%', height: '60vh' }}
        onSubmit={sendMessage}
      />
    </div>
  );
}

TypeformWidget.propTypes = {
  surveyId: PropTypes.string.isRequired,
  memberId: PropTypes.number.isRequired,
  formType: PropTypes.string,
};

TypeformWidget.defaultProps = {
  formType: '',
};

export default TypeformWidget;
