import React, { forwardRef, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import classNames from 'classnames';
import InputWrapper from './InputWrapper';
import RichtextInputToolbar from './RichtextInputToolbar';
import { SmartPhrasesPropType } from '../documentation/helpers/types';

import 'react-quill/dist/quill.snow.css';

const removeHTMLFromString = (value) => {
  const parser = new DOMParser();
  const { textContent } = parser.parseFromString(value, 'text/html').documentElement;

  return textContent;
};

// By default ReactQuill returns <p><br></p> when it's empty
// We need to check if it's empty or not
export const isValueEmpty = (value) => !removeHTMLFromString(value).trim();

const RichTextInput = forwardRef(
  ({ label, name, value, onBlur, onChange, placeholder, error, disabled, required, isVertical, smartPhrases }, ref) => {
    const [toolbarNode, setToolbarNode] = useState();
    const isSmartPhraseAdded = useRef(false);
    const quillRef = useRef();

    const addSmartPhrase = (phrase) => {
      isSmartPhraseAdded.current = true;
      const quill = quillRef.current.editor;
      const cursorPosition = quill.getSelection().index;
      quill.pasteHTML(cursorPosition, phrase);
      quill.setSelection(cursorPosition + removeHTMLFromString(phrase).length);
    };

    const modules = useMemo(
      () => ({
        toolbar: {
          container: toolbarNode,
          handlers: {
            addSmartPhrase,
          },
        },
      }),
      [toolbarNode]
    );

    return (
      <div data-testid="richtext" className={classNames({ error, disabled })}>
        <InputWrapper label={label} name={name} required={required} error={error} isVertical={isVertical}>
          <RichtextInputToolbar ref={setToolbarNode} smartPhrases={smartPhrases} />
          {!!toolbarNode && (
            <ReactQuill
              ref={(e) => {
                if (ref) ref(e);
                quillRef.current = e;
              }}
              theme="snow"
              defaultValue={value}
              onChange={(currentValue, _, source) => {
                if (source === 'api' && !isSmartPhraseAdded.current) return;

                onChange(isValueEmpty(currentValue) ? '' : currentValue.replaceAll('&nbsp;', ''));
                isSmartPhraseAdded.current = false;
              }}
              onBlur={onBlur}
              readOnly={disabled}
              placeholder={placeholder}
              modules={modules}
            />
          )}
        </InputWrapper>
      </div>
    );
  }
);

RichTextInput.displayName = 'RichTextInput';

RichTextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isVertical: PropTypes.bool,
  smartPhrases: SmartPhrasesPropType,
};

RichTextInput.defaultProps = {
  label: null,
  placeholder: null,
  error: null,
  disabled: false,
  required: false,
  isVertical: true,
  smartPhrases: null,
};

export default RichTextInput;
