import React from 'react';
import PropTypes from 'prop-types';
import Filters from '../../Filters';
import FilterZipcode from '../../FilterZipcode';
import { FILTER_TYPES } from './constants';
import Loader from '../../shared/Loader';

function AvailableFilters({
  currentlyAppliedFilters,
  setFilter,
  activeTab,
  availabilityData,
  daysData,
  empanelmentClinicalModelData,
  facilitiesData,
  hoursData,
  modalityData,
  officeManagerData,
  staffData,
  statesData,
  isLoading,
  testID,
}) {
  if (!empanelmentClinicalModelData) {
    return null;
  }

  const isHomeGroupTab = activeTab === 'home_group';
  const getStateChangeHandler = (type) => (selectedItem) => setFilter(type, selectedItem);
  const getSingleSelectStateChangeHandler = (type) => (selectedItem) => setFilter(type, [selectedItem]);

  const renderClinicalModelFilter = () => {
    const label = isHomeGroupTab ? 'Clinical Model' : 'Type';
    if (empanelmentClinicalModelData.length) {
      return (
        <Filters
          testID={`clinical-model-${testID}`}
          label={label}
          type={FILTER_TYPES.clinicalModel}
          data={empanelmentClinicalModelData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.clinicalModel)}
          placeholder={`Search ${label}`}
          value={currentlyAppliedFilters[FILTER_TYPES.clinicalModel]}
          allowEmpty
        />
      );
    }

    return null;
  };

  return (
    <div className="border border-gray-300 rounded bg-gray-100 mt-6 flex flex-row relative">
      {isLoading && <Loader />}
      <div className="p-3 flex-1 flex flex-col gap-4">
        <Filters
          label="States"
          type={FILTER_TYPES.state}
          data={statesData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.state)}
          testID={`states-${testID}`}
          placeholder="Search State"
          value={currentlyAppliedFilters[FILTER_TYPES.state]}
          allowEmpty
        />
        <Filters
          label="Facilities"
          type={FILTER_TYPES.facility}
          data={facilitiesData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.facility)}
          testID={`facilities-${testID}`}
          placeholder="Search Facility"
          value={currentlyAppliedFilters[FILTER_TYPES.facility]}
          allowEmpty
        />
        <Filters
          label="Group Day"
          type={FILTER_TYPES.days}
          data={daysData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.days)}
          testID={`days-${testID}`}
          placeholder="Search Day"
          value={currentlyAppliedFilters[FILTER_TYPES.days]}
          allowEmpty
        />
        <Filters
          label="Group Time"
          type={FILTER_TYPES.time}
          data={hoursData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.time)}
          testID={`time-${testID}`}
          placeholder="Search for a time"
          value={currentlyAppliedFilters[FILTER_TYPES.time]}
          allowEmpty
        />
      </div>
      {isHomeGroupTab && (
        <div className="p-3 flex-1 flex flex-col gap-4 border-gray-300 border-l">
          <Filters
            label="Modality"
            type={FILTER_TYPES.modality}
            data={modalityData}
            stateChanger={getStateChangeHandler(FILTER_TYPES.modality)}
            testID={`modality-${testID}`}
            placeholder="Search Modality"
            value={currentlyAppliedFilters[FILTER_TYPES.modality]}
            allowEmpty
          />
          {renderClinicalModelFilter()}
          <Filters
            testID={`availability-${testID}`}
            label="Availability"
            type={FILTER_TYPES.availability}
            data={availabilityData}
            stateChanger={getSingleSelectStateChangeHandler(FILTER_TYPES.availability)}
            placeholder="Choose Availability"
            value={currentlyAppliedFilters[FILTER_TYPES.availability]}
            isMulti={false}
            allowEmpty
          />
          <FilterZipcode
            currentlyAppliedZipcode={currentlyAppliedFilters[FILTER_TYPES.zipcode]}
            testID={`filters-zipcode-${testID}`}
            onSubmit={getStateChangeHandler(FILTER_TYPES.zipcode)}
          />
        </div>
      )}
      <div className="p-3 flex-1 flex flex-col gap-4 border-gray-300 border-l">
        {!isHomeGroupTab && renderClinicalModelFilter()}
        <Filters
          label="Counselors"
          type={FILTER_TYPES.counselor}
          data={staffData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.counselor)}
          testID={`counselors-${testID}`}
          placeholder="Search Counselors"
          value={currentlyAppliedFilters[FILTER_TYPES.counselor]}
          allowEmpty
        />
        <Filters
          label="Office Managers"
          type={FILTER_TYPES.officeManager}
          data={officeManagerData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.officeManager)}
          testID={`OMs-${testID}`}
          placeholder="Search Office Managers"
          value={currentlyAppliedFilters[FILTER_TYPES.officeManager]}
          allowEmpty
        />
        <Filters
          label="Providers"
          type={FILTER_TYPES.provider}
          data={staffData}
          stateChanger={getStateChangeHandler(FILTER_TYPES.provider)}
          testID={`providers-${testID}`}
          placeholder="Search Providers"
          value={currentlyAppliedFilters[FILTER_TYPES.provider]}
          allowEmpty
        />
      </div>
    </div>
  );
}

AvailableFilters.propTypes = {
  currentlyAppliedFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  availabilityData: PropTypes.array.isRequired,
  daysData: PropTypes.array.isRequired,
  empanelmentClinicalModelData: PropTypes.array.isRequired,
  facilitiesData: PropTypes.array.isRequired,
  hoursData: PropTypes.array.isRequired,
  modalityData: PropTypes.array.isRequired,
  officeManagerData: PropTypes.array.isRequired,
  staffData: PropTypes.array.isRequired,
  statesData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  testID: PropTypes.string.isRequired,
};

export default AvailableFilters;
