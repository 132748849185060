import React from 'react';
import { CircularProgress } from '@mui/material';

function Loader() {
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 flex items-center justify-center z-10">
      <CircularProgress size={32} className="text-gray-700" />
    </div>
  );
}

export default Loader;
