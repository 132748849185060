import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import Button from '../../../shared/Button';
import useFilterData from './fetch-filter-data';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from '../../../shared/AppliedFilters';
import Toasts from '../../../shared/Toasts';
import StaffMemberFacilitiesTab from './StaffMemberFacilitiesTab';
import useToasts from '../../../shared/hooks/use-toasts';
import useFilters from '../../../shared/hooks/use-filters';
import Tabs from '../../../Tabs';
import { DEFAULT_FILTERS, PageTabs, FILTER_TYPES, TAB_IDS } from './constants';
import BookAppointmentTab from './BookAppointmentTab';
import ScheduledTab from './ScheduledTab';
import { AppointmentsURLsController } from './useAppointmentsURLs';
import { OptionsPropTypes, OptionPropType } from '../shared/types';
import { SESSION_STORAGE_KEYS } from '../../../../helpers/constants';

const testID = 'appointmentsMainEmp';
function Main({
  authenticityToken,
  secondaryGroupTypeFeatureFlag,
  appointmentFinderMemberAssignmentPermission,
  viewStaffMemberFacilitiesPermission,
  memberSummaryUrl,
  appointmentFinderUrl,
  reasons,
  currentUserProvider,
}) {
  const { toasts, addToast, removeToast } = useToasts();
  const [activeTab, setTab] = useState(PageTabs[0]);
  const [isTableLoading, setIsTableLoading] = useState();

  const providerFilter = { [FILTER_TYPES.provider]: currentUserProvider ? [currentUserProvider] : [] };

  const allTabsFilters = {
    Scheduled: useFilters({
      ...DEFAULT_FILTERS.scheduled,
      ...providerFilter,
    }),
    'Book Appointment': useFilters({
      ...DEFAULT_FILTERS.bookAppointment,
      ...providerFilter,
    }),
    'Staff Member Facilities': useFilters(DEFAULT_FILTERS.staffMemberFacilities),
  };

  const { filters, setFilter, addFilter, filtersArray } = allTabsFilters[activeTab.name];

  const {
    daysData,
    facilitiesData,
    statesData,
    availabilityData,
    providersData,
    appointmentTypesData,
    isLoading: isFilterDataLoading,
  } = useFilterData();

  useEffect(() => {
    const isAppointmentCancelled = sessionStorage.getItem(SESSION_STORAGE_KEYS.SHOW_APPOINTMENT_CANCELLED_TOAST);

    if (isAppointmentCancelled === 'true') {
      addToast({
        header: 'Appointment has been canceled',
        type: 'error',
      });
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.SHOW_APPOINTMENT_CANCELLED_TOAST);
    }
  }, [addToast]);

  const [showFilters, setShowFilters] = useState(false);
  const FilterBtnIcon = showFilters ? HiChevronUp : HiChevronDown;

  const toggleFilters = () => setShowFilters((s) => !s);

  const tabs = viewStaffMemberFacilitiesPermission
    ? [
        ...PageTabs,
        {
          id: TAB_IDS.STAFF_MEMBER_FACILITIES,
          name: 'Staff Member Facilities',
        },
      ]
    : PageTabs;

  const handleLoading = useCallback((type, tabId, isLoading) => {
    if (!type) return;

    setIsTableLoading((prevState) => ({
      ...prevState,
      [type]: { tabId, isLoading },
    }));
  }, []);

  const isTabLoading =
    isTableLoading &&
    !!Object.values(isTableLoading).find((value) => value?.tabId === activeTab.id && value?.isLoading);
  const isFiltersLoading = isFilterDataLoading || isTabLoading;

  return (
    <AppointmentsURLsController memberSummaryUrl={memberSummaryUrl} appointmentFinderUrl={appointmentFinderUrl}>
      <div data-testid={testID} className="mt-3">
        <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" isSecondaryVariant />
        <div className="border-b border-gray-400 pb-2 flex justify-between flex-row items-end">
          <div>
            <h2 data-testid={`title-${testID}`} className="font-body font-semibold mb-1">
              Appointment Finder
            </h2>
          </div>
          <Button testID={`toggle-filters-button-${testID}`} onClick={toggleFilters} isPrimary classes="max-h-12">
            <div className="flex items-center">
              Apply Filters
              <FilterBtnIcon className="text-2xl ml-2" />
            </div>
          </Button>
        </div>
        <AppliedFilters currentlyAppliedFilters={filtersArray} isLoading={isFiltersLoading} />
        {showFilters && (
          <AvailableFilters
            currentlyAppliedFilters={filters}
            appointmentTypesData={appointmentTypesData}
            availabilityData={availabilityData}
            providersData={providersData}
            daysData={daysData}
            facilitiesData={facilitiesData}
            statesData={statesData}
            setFilter={setFilter}
            addFilter={addFilter}
            activeTabId={activeTab.id}
            isLoading={isFiltersLoading}
            testID={testID}
          />
        )}
        <div className="mt-4">
          <Tabs activeTab={activeTab} tabs={tabs} onChange={setTab} testID={testID} />
          {activeTab.id === TAB_IDS.SCHEDULED && <ScheduledTab filters={filters} onLoading={handleLoading} />}
          {activeTab.id === TAB_IDS.BOOK_APPOINTMENT && (
            <BookAppointmentTab
              authenticityToken={authenticityToken}
              secondaryGroupTypeFeatureFlag={secondaryGroupTypeFeatureFlag}
              appointmentFinderMemberAssignmentPermission={appointmentFinderMemberAssignmentPermission}
              addToast={addToast}
              filters={filters}
              reasons={reasons}
              onLoading={handleLoading}
            />
          )}
          {activeTab.id === TAB_IDS.STAFF_MEMBER_FACILITIES && (
            <StaffMemberFacilitiesTab
              filters={filters}
              providersData={providersData}
              facilitiesData={facilitiesData}
              appointmentTypesData={appointmentTypesData}
              authenticityToken={authenticityToken}
              onLoading={handleLoading}
            />
          )}
        </div>
      </div>
    </AppointmentsURLsController>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  appointmentFinderMemberAssignmentPermission: PropTypes.bool.isRequired,
  viewStaffMemberFacilitiesPermission: PropTypes.bool.isRequired,
  memberSummaryUrl: PropTypes.string.isRequired,
  appointmentFinderUrl: PropTypes.string.isRequired,
  reasons: OptionsPropTypes.isRequired,
  currentUserProvider: OptionPropType,
};

Main.defaultProps = {
  currentUserProvider: null,
};

export default Main;
