import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import ChartingNotesHeader from './ChartingNotesHeader';
import ChartingNotesTemplateSelector from './ChartingNotesTemplateSelector';
import MembersNotesGrid from './MembersNotesGrid';
import useHttp from '../shared/hooks/use-http';
import useToasts from '../shared/hooks/use-toasts';
import { convertObjKeysToCamelCase } from '../../helpers/utils';
import {
  getDateTime,
  getNoteIds,
  getMembersAnswers,
  getUpdatedChartingNoteAnswers,
  getApplyToAllUpdatedNotes,
  getFormAnswerGroup,
  getAddendums,
  getLockedNoteIds,
  getCoSigners,
  getLockData,
  getNewNoteAnswers,
  getBaseNote,
} from './helpers/groupChartingNotesHelpers';
import { MemberPropType, SmartPhrasesPropType } from './helpers/types';
import CoSign from '../empanelment/member_encounter_history/CoSign';
import AddAddendum from '../empanelment/member_encounter_history/AddAddendum';
import EditGroupNotesModal from './EditGroupNotesModal';
import ApplyAnswerToAllModal from './ApplyAnswerToAllModal';
import Toasts from '../shared/Toasts';
import useChartingNote from '../empanelment/member_encounter_history/useChartingNote';

function GroupChartingNotes({
  authenticityToken,
  members,
  membersWithLegacyNotes,
  states,
  appointmentOccurrenceId,
  selectedChartingNoteIds,
  canUserManageChartingNotes,
  sessionIdentifier,
  smartPhrases,
}) {
  const { chartingNotes: previouslyCreatedChartingNotes, isLoading: isPreviouslyCreatedChartingNotesLoading } =
    useChartingNote(selectedChartingNoteIds);
  const { sendRequest: createChartingNote, error: createChartingNoteError } = useHttp();
  const [isCreateChartingNoteLoading, setIsCreateChartingNoteLoading] = useState(false);
  const { isLoading: isUpdateChartingNoteLoading, sendRequest: updateChartingNote } = useHttp();
  const { toasts, addToast, removeToast } = useToasts();
  const {
    toasts: chartingNotesToasts,
    addToast: addChartingNoteToast,
    removeToast: removeChartingNoteToast,
  } = useToasts();
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [isCoSignModalOpen, setIsCoSignModalOpen] = useState(false);
  const [isAddAddendumModalOpen, setIsAddAddendumModalOpen] = useState(false);
  const [memberNotesToEdit, setMemberNotesToEdit] = useState();
  const [currentApplyToAllAnswer, setCurrentApplyToAllAnswer] = useState();

  const handleNotesChange = useCallback(
    (notes) => {
      const baseNote = getBaseNote(notes);
      const lockData = getLockData(notes);
      const answers = {
        ...baseNote,
        ids: getNoteIds(notes),
        formAnswers: baseNote.formAnswers?.map((answer) => getMembersAnswers(notes, answer.customModule.id)),
        lockedNoteIds: getLockedNoteIds(notes),
        coSigners: getCoSigners(notes),
        addendums: getAddendums(notes),
        lockedAt: lockData?.lockedAt,
        lockedBy: lockData?.lockedBy,
      };

      setSelectedNotes((prevState) => [answers, ...prevState]);
    },
    [setSelectedNotes]
  );

  const membersToShow = useMemo(
    () => uniqBy([...members, ...membersWithLegacyNotes], 'id'),
    [members, membersWithLegacyNotes]
  );
  const memberMrns = useMemo(() => membersToShow?.map((member) => member.healthieMrn), [membersToShow]);

  useEffect(() => {
    if (createChartingNoteError && !isCreateChartingNoteLoading) {
      addChartingNoteToast({
        header: 'Create Charting Note Error',
        message:
          'An error occurred while creating the charting note. Please ensure that members have the proper configuration and that the staff member has the appropriate permissions. If the problem persists, contact support.',
        type: 'error',
      });
    }
  }, [addChartingNoteToast, createChartingNoteError, isCreateChartingNoteLoading]);

  useEffect(() => {
    if (previouslyCreatedChartingNotes?.length) {
      setSelectedNotes([]);
      const convertedAndFilteredChartingNotes = convertObjKeysToCamelCase(previouslyCreatedChartingNotes).filter(
        (chartingNote) => chartingNote.batchId && memberMrns.includes(chartingNote.user.id)
      );
      const groupedDataByBatchId = groupBy(convertObjKeysToCamelCase(convertedAndFilteredChartingNotes), 'batchId');
      Object.keys(groupedDataByBatchId).forEach((batchId) => handleNotesChange(groupedDataByBatchId[batchId]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previouslyCreatedChartingNotes, handleNotesChange]);

  const getMemberNotesToEdit = useCallback(() => {
    if (!memberNotesToEdit) return null;

    const { memberHealthieMrn, selectedFormAnswerId } = memberNotesToEdit;
    const { firstName, lastName } = members.find((member) => member.healthieMrn === memberHealthieMrn) || {};
    const notes = selectedNotes
      .map((selectedNote) =>
        selectedNote.ids[memberHealthieMrn]
          ? {
              ...selectedNote,
              id: selectedNote.ids[memberHealthieMrn],
              formAnswers: selectedNote.formAnswers?.map((formAnswer) => formAnswer[memberHealthieMrn]).filter(Boolean),
              lockedAt: selectedNote.lockedAt?.[memberHealthieMrn],
              lockedBy: selectedNote.lockedBy?.[memberHealthieMrn],
            }
          : null
      )
      .filter((note) => note);

    return { memberName: `${firstName} ${lastName}`, selectedFormAnswerId, notes };
  }, [memberNotesToEdit, selectedNotes, members]);

  const latestNoteDate =
    selectedNotes?.length && getDateTime(Math.max(...selectedNotes.map((note) => new Date(note.updatedAt))));
  const memberIds = useMemo(() => members?.map((member) => member.id), [members]);
  const notesToEdit = useMemo(getMemberNotesToEdit, [getMemberNotesToEdit]);

  const handleLockAndSign = ({ lockedAt, lockedBy, lockedNoteIds }) => {
    setSelectedNotes((prevState) =>
      prevState.map((selectedNote) => {
        const allNoteIds = selectedNote?.ids && Object.values(selectedNote.ids);
        if (lockedNoteIds?.some((lockedNoteId) => allNoteIds.includes(lockedNoteId))) {
          return {
            ...selectedNote,
            lockedNoteIds: selectedNote?.lockedNoteIds
              ? [...selectedNote.lockedNoteIds, ...lockedNoteIds]
              : lockedNoteIds,
            lockedAt: {
              ...selectedNote.lockedAt,
              ...lockedAt,
            },
            lockedBy: {
              ...selectedNote.lockedBy,
              ...lockedBy,
            },
          };
        }

        return selectedNote;
      })
    );
  };

  const addCoSignerToNote = (selectedNote, signedAt, signedBy, coSignedFor, qualifications) => {
    const newCoSigner = { signedAt, signedBy, coSignedFor, qualifications };
    return {
      ...selectedNote,
      coSigners: selectedNote?.coSigners ? [...selectedNote.coSigners, newCoSigner] : [newCoSigner],
    };
  };

  const handleCoSign = ({ chartingNoteIds: coSignedChartingNoteIds, signedAt, signedBy, qualifications }) => {
    setSelectedNotes((prevState) =>
      prevState.map((selectedNote) => {
        const allNoteIds = selectedNote?.ids && Object.values(selectedNote.ids);
        if (coSignedChartingNoteIds?.some((chartingNoteId) => allNoteIds.includes(chartingNoteId))) {
          return addCoSignerToNote(selectedNote, signedAt, signedBy, coSignedChartingNoteIds, qualifications);
        }

        return selectedNote;
      })
    );
  };

  const addAddendumToNote = ({ selectedNote, createdAt, createdBy, addedFor, addendum, qualifications }) => {
    const newAddendum = { addedFor, content: addendum, createdAt, createdBy, qualifications };
    return {
      ...selectedNote,
      addendums: selectedNote?.addendums ? [...selectedNote.addendums, newAddendum] : [newAddendum],
    };
  };

  const handleAddAddendum = ({ addendum, createdAt, createdBy, chartingNoteIds, qualifications }) => {
    setSelectedNotes((prevState) =>
      prevState.map((selectedNote) => {
        const allNoteIds = selectedNote?.ids && Object.values(selectedNote.ids);
        if (chartingNoteIds?.some((chartingNoteId) => allNoteIds.includes(chartingNoteId))) {
          return addAddendumToNote({
            selectedNote,
            addendum,
            createdAt,
            createdBy,
            qualifications,
            addedFor: chartingNoteIds,
          });
        }

        return selectedNote;
      })
    );
  };

  const handleChartingNotesDelete = (noteIds) =>
    setSelectedNotes((prevState) =>
      prevState
        .map((selectedNote) => {
          const mrnsNotToDelete = Object.entries(selectedNote.ids)
            .filter(([_, noteId]) => !noteIds.includes(noteId))
            .map(([mrn, _]) => mrn);

          return mrnsNotToDelete?.length
            ? {
                ...selectedNote,
                ids: mrnsNotToDelete.reduce((prevMrns, mrn) => ({ ...prevMrns, [mrn]: selectedNote.ids[mrn] }), null),
                formAnswers: selectedNote.formAnswers.map((formAnswer) =>
                  mrnsNotToDelete.reduce((prevMrns, mrn) => ({ ...prevMrns, [mrn]: formAnswer[mrn] }), null)
                ),
              }
            : null;
        })
        .filter((selectedNote) => selectedNote)
    );

  const editChartingNote = useCallback(
    async (noteIds, noteAnswers) => {
      const response = await updateChartingNote('/staff/documentation/charting_notes', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          charting_note_ids: noteIds,
          form_answers: noteAnswers,
        },
      });

      const newNotes = convertObjKeysToCamelCase(
        response?.map((item) => item?.data?.updateFormAnswerGroup?.formAnswerGroup)
      );
      const newNoteIds = newNotes.map((newNote) => newNote.id);

      setSelectedNotes((prevState) =>
        prevState.map(
          (selectedNote) =>
            newNoteIds.find((newNotesId) => Object.values(selectedNote?.ids).includes(newNotesId))
              ? {
                  ...selectedNote,
                  formAnswers: getUpdatedChartingNoteAnswers(selectedNote, newNotes),
                  updatedAt: newNotes[0]?.updatedAt,
                }
              : selectedNote,
          {}
        )
      );

      return newNoteIds;
    },
    [updateChartingNote, setSelectedNotes, authenticityToken]
  );

  const handleChartingNoteUpdate = useCallback(
    async (noteId, data) => {
      const newNoteAnswers = getNewNoteAnswers(notesToEdit.notes, noteId, data);
      await editChartingNote([noteId], [newNoteAnswers]);
    },
    [editChartingNote, notesToEdit]
  );

  const handleChartingNotesUpdate = async (notes) => {
    if (!notes) {
      setMemberNotesToEdit(null);
      return;
    }

    try {
      const updatedNotes = Object.entries(notes).map(([noteId, data]) => ({
        id: noteId,
        answers: getNewNoteAnswers(notesToEdit.notes, noteId, data),
      }));

      const newNoteIds = await editChartingNote(
        updatedNotes.map((note) => note.id),
        updatedNotes.map((note) => note.answers)
      );

      if (newNoteIds?.length < updatedNotes?.length)
        throw new Error('Some charting notes were not updated successfully');

      setMemberNotesToEdit(null);
      addToast({
        header: 'Success',
        message: 'Charting notes saved successfully.',
        type: 'success',
      });
    } catch (e) {
      window.Sentry.captureException(e);
      addToast({
        header: 'Failed',
        message: 'Something went wrong.',
        type: 'error',
      });
    }
  };

  const handleNotesGridRowClick = (memberHealthieMrn, selectedFormAnswerId) =>
    setMemberNotesToEdit({ memberHealthieMrn, selectedFormAnswerId });

  const handleApplyAnswerToAll = async (answers, noteId) => {
    const notesToUpdate = selectedNotes?.find((note) => Object.values(note.ids)?.includes(noteId));
    const memberHealthieMrns = Object.entries(notesToUpdate.ids)
      .filter(([_, value]) => value !== noteId && !notesToUpdate.lockedNoteIds?.includes(value))
      .map(([key, _]) => key);

    try {
      if (!memberHealthieMrns?.length) throw new Error();

      const updatedNotes = getApplyToAllUpdatedNotes(memberHealthieMrns, notesToUpdate, answers);
      const updatedNoteIds = updatedNotes.map(({ formId }) => formId);
      const updatedNoteAnswers = updatedNotes.map(({ formAnswers }) => formAnswers);
      const newNoteIds = await editChartingNote(updatedNoteIds, updatedNoteAnswers);

      if (newNoteIds?.length < updatedNoteIds?.length) throw new Error();

      addToast({
        header: 'Success',
        message: 'Selected answer has been successfully applied to the entire group.',
        type: 'success',
      });
    } catch (e) {
      addToast({
        header: 'Failed',
        message: "Something went wrong. Your answer hasn't been applied to the entire group.",
        type: 'error',
      });
    }
    setCurrentApplyToAllAnswer(null);
  };

  const handleGridApplyAnswerToAll = (answer, noteId) => setCurrentApplyToAllAnswer({ answer, noteId });

  const handleApplyToAllConfirmation = () =>
    handleApplyAnswerToAll(currentApplyToAllAnswer.answer, currentApplyToAllAnswer.noteId);

  const lockedAndSignedChartingNotes = selectedNotes.filter((note) => note.lockedAt && note.hasPermissionToEdit);
  const hasPermissionToEditSomeChartingNotes = selectedNotes.some((note) => note.hasPermissionToEdit);

  if (isPreviouslyCreatedChartingNotesLoading)
    return (
      <div className="flex justify-center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <Toasts toasts={toasts} removeToast={removeToast} isSecondaryVariant />
      <CoSign
        isGroupNote
        authenticityToken={authenticityToken}
        onSuccess={handleCoSign}
        isModalOpen={isCoSignModalOpen}
        closeModal={() => setIsCoSignModalOpen(false)}
        chartingNotes={lockedAndSignedChartingNotes}
      />
      <AddAddendum
        isGroupNote
        authenticityToken={authenticityToken}
        onSuccess={handleAddAddendum}
        isModalOpen={isAddAddendumModalOpen}
        closeModal={() => setIsAddAddendumModalOpen(false)}
        chartingNotes={lockedAndSignedChartingNotes}
        members={members.map((member) => ({
          value: member.healthieMrn,
          label: `${member.firstName} ${member.lastName}`,
        }))}
      />
      <EditGroupNotesModal
        isOpen={!!notesToEdit}
        onSave={handleChartingNotesUpdate}
        notesToEdit={notesToEdit}
        onChartingNoteUpdate={handleChartingNoteUpdate}
        onApplyAnswerToAll={handleApplyAnswerToAll}
        disabled={isUpdateChartingNoteLoading}
        states={states}
        smartPhrases={smartPhrases}
      />
      <ApplyAnswerToAllModal
        isOpen={!!currentApplyToAllAnswer}
        onClose={() => setCurrentApplyToAllAnswer(null)}
        onContinue={handleApplyToAllConfirmation}
        isLoading={isUpdateChartingNoteLoading}
      />
      <div className="bg-white px-4 pb-4">
        <ChartingNotesHeader
          latestNoteDate={latestNoteDate}
          disabled={!lockedAndSignedChartingNotes.length}
          onAddAddendumClick={() => setIsAddAddendumModalOpen(true)}
          onCoSignClick={() => setIsCoSignModalOpen(true)}
          hideActionButtons={!hasPermissionToEditSomeChartingNotes}
        />
        <hr />
        <Toasts toasts={chartingNotesToasts} removeToast={removeChartingNoteToast} />
        <div className="py-5">
          <ChartingNotesTemplateSelector
            authenticityToken={authenticityToken}
            memberIds={memberIds}
            isLoading={isCreateChartingNoteLoading}
            appointmentOccurrenceId={appointmentOccurrenceId}
            sessionIdentifier={sessionIdentifier}
            createChartingNote={createChartingNote}
            isDisabled={!canUserManageChartingNotes || !members?.length}
            onTemplateChange={(notes) => {
              const parsedNotes = convertObjKeysToCamelCase(notes).map(getFormAnswerGroup);
              handleNotesChange(parsedNotes);
            }}
            setIsLoading={setIsCreateChartingNoteLoading}
          />
        </div>
        <div className="relative">
          {isCreateChartingNoteLoading && (
            <div className="absolute w-full h-full bg-white bg-opacity-50 no-data-loader z-50">
              <CircularProgress />
            </div>
          )}
          <MembersNotesGrid
            formAnswerGroups={selectedNotes}
            members={membersToShow}
            authenticityToken={authenticityToken}
            onChartingNotesDelete={handleChartingNotesDelete}
            onChartingNoteLockAndSign={handleLockAndSign}
            onRowClick={handleNotesGridRowClick}
            isEditingDisabled={!canUserManageChartingNotes}
            onApplyAnswerToAll={handleGridApplyAnswerToAll}
          />
        </div>
      </div>
    </>
  );
}

GroupChartingNotes.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(MemberPropType).isRequired,
  membersWithLegacyNotes: PropTypes.arrayOf(MemberPropType),
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  appointmentOccurrenceId: PropTypes.number,
  selectedChartingNoteIds: PropTypes.arrayOf(PropTypes.string),
  canUserManageChartingNotes: PropTypes.bool.isRequired,
  sessionIdentifier: PropTypes.string,
  smartPhrases: SmartPhrasesPropType,
};

GroupChartingNotes.defaultProps = {
  membersWithLegacyNotes: [],
  appointmentOccurrenceId: null,
  sessionIdentifier: null,
  selectedChartingNoteIds: [],
  smartPhrases: null,
};

export default GroupChartingNotes;
