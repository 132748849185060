import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../Table';
import useGroupAppointments from './useGroupAppointments';
import { TAB_IDS } from './constants';

export const TABLE_HEADERS = [
  {
    name: 'Group Meeting',
    showColumn: true,
  },
  {
    name: 'Type',
    showColumn: true,
  },
  {
    name: 'Staff',
    showColumn: true,
  },
  {
    name: 'Members',
    showColumn: true,
  },
  {
    name: '',
    showColumn: true,
    isClickable: false,
    actionColumn: true,
  },
];

function GroupAppointments({ filters, onLoading }) {
  const { currentData, currentPage, fetchData, handleSort, sortedBy, isLoading } = useGroupAppointments(filters);

  useEffect(() => onLoading && onLoading('groupAppointments', TAB_IDS.SCHEDULED, isLoading), [onLoading, isLoading]);

  return (
    <div className="flex flex-col gap-4">
      <h1>Group Appointments</h1>
      <div className="border border-gray-400 mb-6 shadow-md rounded-lg overflow-hidden bg-white">
        <Table
          tableType="groupAppointments"
          testID="groupAppointments"
          showShadow={false}
          enableActionsColumn
          paginationVariant="numbered"
          pageSize={10}
          currentPage={currentPage}
          tableRows={currentData.jsonData}
          pageChange={fetchData}
          isLastPage={currentData.isLastPage}
          headerButtonClick={handleSort}
          currentSort={sortedBy}
          totalCountRecords={currentData.totalCount}
          tableColumnHeaders={TABLE_HEADERS}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

GroupAppointments.propTypes = {
  filters: PropTypes.object.isRequired,
  onLoading: PropTypes.func,
};

GroupAppointments.defaultProps = {
  onLoading: null,
};

export default GroupAppointments;
