/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import axios from 'axios';

// COMPONENTS
import PackageRates from './PackageRates';
import PackageContent from './PackageContent';
import PackageDimensions from './PackageDimensions';
import NextButton from '../shared/NextButton';

// HOOKS
import useCachedShipmentData from '../hooks/use-cached-shipment-data';

// HELPERS
import { errorsExist, isEmptyObject } from '../../../helpers/AssortedHelpers';

function ShipmentContents(props) {
  // CACHED DATA
  const { labelAddress, labelContents, labelCompleted, fetchCachedShipmentData } = useCachedShipmentData(
    props?.selectedLabel?.labelName
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => fetchCachedShipmentData(props?.selectedLabel?.labelName),
    [props?.selectedLabel?.labelName, props.updateCache]
  );

  // SET DATA
  const [contents, setContents] = useState({});
  const [rateError, setRateError] = useState(false);
  const [poBoxError, setPoBoxError] = useState(false);
  const [calculateRatesLoading, setCalculateRatesLoading] = useState(false);

  useEffect(() => setContents(labelContents), [labelContents]);

  // PACKAGE OPTIONS
  const [packageOptions, setPackageOptions] = useState(null);

  // SHOW CONTAINER
  const [toShow, setToShow] = useState(true);
  useEffect(() => {
    if (labelCompleted) {
      setToShow(false);
      setPackageOptions(null);
    } else {
      const cachedPrevDataIsComplete = !labelContents?.isComplete && labelAddress.isComplete;
      const currentPrevDataIsComplete = !labelContents?.isComplete && props.selectedLabel?.labelAddress?.isComplete;
      setToShow(cachedPrevDataIsComplete || currentPrevDataIsComplete);
    }
  }, [
    labelContents?.isComplete,
    labelAddress.isComplete,
    labelCompleted,
    props.selectedLabel?.labelAddress,
    props.updateCache,
  ]);

  // RESET RATES
  const { date, length, width, height, distanceUnit, weight, massUnit, packaging } = labelContents;
  useEffect(() => {
    // IF ADDRESS UPDATES
    // OR IF LABEL CONTENTS PACKAGE INFO UPDATE
    // RESET RATES
    setPackageOptions(null);
  }, [
    props.updateCache,
    // LABEL CONTENTS DATA TO WATCH
    date,
    length,
    width,
    height,
    distanceUnit,
    weight,
    massUnit,
    packaging?.length,
    packaging?.width,
    packaging?.height,
  ]);

  // ERROR HANDLING
  const requiredText = 'This field is required';
  const [formErrors, setFormErrors] = useState({});
  const setError = (field, error) => {
    formErrors[field] = error;
    setFormErrors({ ...formErrors });
  };

  const validateObj = (obj, key) => {
    if (isEmptyObject(obj)) {
      setError(key, requiredText);
    } else {
      setError(key, null);
    }
  };

  const validateData = () => {
    validateObj(contents?.packaging, 'packaging');

    if (contents?.packaging?.value === 'custom') {
      validateObj(contents?.length, 'length');
      validateObj(contents?.width, 'width');
      validateObj(contents?.height, 'height');
    }

    validateObj(contents?.weight, 'weight');
    validateObj(contents?.date, 'date');
  };

  const setCorrectRateError = (error) => {
    if (error?.response?.data?.error === 'PO Box Error') {
      setPoBoxError(true);
      setRateError(false);
    } else {
      setPoBoxError(false);
      setRateError(true);
    }
    window.Sentry.captureException(error);
    setCalculateRatesLoading(false);
  };

  // FETCH RATES
  const fetchRates = async () => {
    const { add, content } = await fetchCachedShipmentData(props?.selectedLabel?.labelName);
    setCalculateRatesLoading(true);
    const params = {
      address_from: {
        name: add?.selectedStaff?.label,
        phone: add?.staffPhone,
        email: add?.staffEmail,
        ...add?.location?.address_information,
      },
      address_to: {
        name: add?.members[0]?.name,
        phone: add?.memberPhone,
        email: add?.memberEmail,
        ...add?.memberLocation?.address_information,
      },
      parcels: {
        template: content?.packaging?.template,
        length: content?.length || content?.packaging?.length,
        width: content?.width || content?.packaging?.width,
        height: content?.height || content?.packaging?.height,
        distance_unit: content?.distanceUnit || 'in',
        weight: content?.weight,
        mass_unit: content?.massUnit?.label || 'lb',
      },
      shipment_date: content?.date,
    };

    axios
      .get('/staff/uds_supply/get_rates', { params })
      .then((response) => {
        const options = response.data;
        setPackageOptions(options);
        if (options?.length === 0) {
          setRateError(true);
        } else {
          setRateError(false);
          setPoBoxError(false);
        }
        setCalculateRatesLoading(false);
      })
      .catch((error) => {
        setCorrectRateError(error);
      });
  };

  // SET CACHING DATA
  const setData = (data) => {
    setContents({ ...data });
    window.localStorage.setItem(`shipment-contents-${props.selectedLabel?.labelName}`, JSON.stringify(data));

    fetchCachedShipmentData(props?.selectedLabel?.labelName);
  };

  const validateForRates = () => {
    validateData();

    if (!errorsExist(formErrors)) {
      fetchRates();
    }
  };

  return (
    <>
      {toShow && (
        <>
          <PackageContent
            labelName={props.selectedLabel?.labelName}
            members={labelAddress?.members || props.selectedLabel?.labelAddress?.members}
          />

          <PackageDimensions
            labelContents={contents}
            formErrors={formErrors}
            onShowRates={validateForRates}
            onSetData={(data) => setData(data)}
            calculateRatesLoading={calculateRatesLoading}
          />

          <PackageRates
            labelContents={contents}
            packageOptions={packageOptions}
            formErrors={formErrors}
            onSetData={(data) => setData(data)}
            rateError={rateError}
            poBoxError={poBoxError}
            isRegionalDirector={props.isRegionalDirector}
          />

          <NextButton
            disabled={!packageOptions}
            classes="w-full"
            text="PURCHASE LABEL"
            onClick={() => {
              validateData();

              if (!errorsExist(formErrors)) {
                setData(contents);

                props.onSetData(contents);
                props.onPurchaseLabel();
              }
            }}
          />
        </>
      )}
    </>
  );
}

ShipmentContents.propTypes = {
  selectedLabel: PropTypes.object.isRequired,
  updateCache: PropTypes.bool.isRequired,
  onSetData: PropTypes.func.isRequired,
  onPurchaseLabel: PropTypes.func.isRequired,
  isRegionalDirector: PropTypes.bool.isRequired,
};

export default ShipmentContents;
