import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GroupsModal from '../GroupsModal';
import Accordion from '../shared/Accordion';
import NoteReadMode from './note_read_mode_view/NoteReadMode';
import HealthieForm from './HealthieForm';
import { prepareNoteReadModeProps, getDateTime } from '../empanelment/member_encounter_history/helpers';
import { FormAnswerGroupPropType, SmartPhrasesPropType } from './helpers/types';
import { getUpdatedFormAnswersInputValues } from './helpers/groupChartingNotesHelpers';

function EditGroupNotesModal({
  isOpen,
  onSave,
  notesToEdit,
  onChartingNoteUpdate,
  disabled,
  states,
  onApplyAnswerToAll,
  smartPhrases,
}) {
  const [isNoteUpdating, setIsNoteUpdating] = useState(false);
  const [formAnswersInputValues, setFormAnswersInputValues] = useState();

  useEffect(() => {
    if (!isOpen && formAnswersInputValues) setFormAnswersInputValues(null);
  }, [isOpen, formAnswersInputValues]);

  const handleChange = useCallback(
    (formId, inputValues, isDirty) =>
      setFormAnswersInputValues((prevState) =>
        getUpdatedFormAnswersInputValues(prevState, formId, inputValues, isDirty)
      ),
    []
  );

  return (
    <GroupsModal
      isOpen={isOpen}
      header={notesToEdit?.memberName}
      footerButtons={[
        {
          label: 'Save and Close',
          isPrimary: true,
          onClick: () => onSave(formAnswersInputValues),
          isLoading: disabled,
        },
      ]}
      isScrollable={false}
      size="large"
    >
      <div className="overflow-y-auto">
        {notesToEdit?.notes?.map((note) => {
          const isOpenByDefault = !!note.formAnswers.find(
            (formAnswer) => formAnswer.id === notesToEdit.selectedFormAnswerId
          );

          return (
            <div key={note.id}>
              <Accordion
                title={note.name}
                description={`Added by ${note.filler?.name} on ${getDateTime(note.createdAt)}`}
                isOpenByDefault={isOpenByDefault}
              >
                {(note.lockedAt && note.lockedNoteIds?.includes(note.id)) || !note.hasPermissionToEdit ? (
                  <NoteReadMode
                    note={prepareNoteReadModeProps(note)}
                    isFullWidthSet
                    selectedFormAnswerId={notesToEdit.selectedFormAnswerId}
                  />
                ) : (
                  <HealthieForm
                    formId={note.id}
                    formAnswers={note.formAnswers}
                    onUpdate={onChartingNoteUpdate}
                    onChange={handleChange}
                    isNoteUpdating={isNoteUpdating}
                    setIsNoteUpdating={setIsNoteUpdating}
                    disabled={disabled}
                    states={states}
                    isVertical={false}
                    onApplyAnswerToAll={onApplyAnswerToAll}
                    smartPhrases={smartPhrases}
                    selectedFormAnswerId={notesToEdit.selectedFormAnswerId}
                  />
                )}
              </Accordion>
            </div>
          );
        })}
      </div>
    </GroupsModal>
  );
}

EditGroupNotesModal.propTypes = {
  isOpen: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  notesToEdit: PropTypes.arrayOf(FormAnswerGroupPropType).isRequired,
  onChartingNoteUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  onApplyAnswerToAll: PropTypes.func.isRequired,
  smartPhrases: SmartPhrasesPropType,
};

EditGroupNotesModal.defaultProps = {
  isOpen: false,
  disabled: false,
  smartPhrases: null,
};

export default EditGroupNotesModal;
